import React, { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import Home from './components/pages/home';
import Wrapper from './components/wrapper/wrapper';

import propsIta from './components/props/ita';
import propsEng from './components/props/eng';
import propsSpa from './components/props/spa';
import propsFra from './components/props/fra';
import propsGer from './components/props/ger';

import Categoria from './components/pages/categoria';
import User from './components/pages/user';
import Ricerca from './components/pages/ricerca';
import Filtri from './components/pages/filtri';
import DettaglioApp from './components/pages/dettaglioApp';


import { ReactComponent as Loading } from './risorse/icone/loading.svg'


import getSpreadsheetData from './components/functions/getSpreadsheetData';
import FormSpreadSheet from './components/pages/formSpreadSheet';


import { connect } from 'react-redux';


//persist
import { changeField } from './redux/actions';
//persist

const App = (props) => { 

  const [darkTheme, setDarkTheme] = useState(props.db.Dark);
  const [currentPage, setCurrentPage] = useState(0);
  const [inputFocus, setInputFocus] = useState(1)
  const [clickRicercaNavbar, setClickRicercaNavbar] = useState(0)

  /*
    0: home
    1: ricerca
    2: user
    3: filtri
    4: categorie e applicazioni
  */

  const [lingua,setLingua] = useState(props.db.Lingua)
  const [unita,setUnita] = useState(props.db.Unita)

  const [primoAccesso,setPrimoAccesso] = useState(props.db.PrimoAccesso)
  const [tour,setTour] = useState(props.db.Tour)

  useEffect(()=>{  
    
    if (window.location.hostname.indexOf('chefsguide.irinoxprofessional.com') === -1) {
      window.location.replace("https://chefsguide.irinoxprofessional.com");
    }
    
    if (window.innerWidth>=1200){
      /* se lo schermo è piu grande di 1200 togli il tour, perche non c'è la navbar */
      setTour(1);
      setPrimoAccesso(1);
    }
  },[])


  /* sistemazione del persist */

  useEffect(()=>{
    props.change("Dark",darkTheme);
  },[darkTheme])

  useEffect(()=>{
    props.change("Lingua",lingua);
  },[lingua])

  useEffect(()=>{
    props.change("Unita",unita);
  },[unita])

  useEffect(()=>{
    props.change("PrimoAccesso",primoAccesso);
  },[primoAccesso])

  useEffect(()=>{
    props.change("Tour",tour);
  },[tour])

  /* sistemazione del persist */

  
  /*
    0: ita
    1: eng
    2: fra
    3: spa
    4: ger
  */
  var currentProps;

  switch (lingua) {
    case 0:
      currentProps=propsIta;
      break;

    case 1:
      currentProps=propsEng;
      break;

    case 2:
      currentProps=propsSpa;
      break;

    case 3:
      currentProps=propsFra;
      break;

    case 4:
      currentProps=propsGer;
      break;
  }  

  /* AGGIORNAMENTO META TAGS */

  useEffect(()=>{
    if(lingua===0){
      document.title="Guida tempi e temperature"
      document.head.children[4].content = "La tua guida Irinox sempre a portata di mano."
    }
    else if(lingua===1){
      document.title = "Guide on times and temperatures"
      document.head.children[4].content = "Your Irinox handy guide."
    }
    else if(lingua===2){
      document.title = "Guía de tiempos y temperatura"
      document.head.children[4].content = "Tu guía Irinox siempre a mano."
    }
    else if(lingua===3){
      document.title = "Heures et températures indicatives"
      document.head.children[4].content = "Votre guide Irinox toujours à portée de main."
    }
    else if(lingua===4){
      document.title = "Richtzeiten und Temperaturen"
      document.head.children[4].content = "Ihr Irinox-guide immer zur hand."
    }
  },[lingua])

  /* AGGIORNAMENTO META TAGS */
  
  if(darkTheme){
    document.body.style = 'background: #121212;';
  }
  else{
    document.body.style = 'background: #FAFBFB;';
  }

  const [inputValue, setInputValue] = useState("")


  const [filtriAttivi, setFiltriAttivi] = useState([])

  //butto i data delle nuove app/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  const [data,setData] = useState([])
  const [dataCounter,setDataCounter] = useState(0)    
  
  //questo è per il primo get data quando si carica la pagina
  useEffect(()=>{    
    if(dataCounter===0){
      getSpreadsheetData(data,setData,lingua,currentProps.filtri)

      if(data.length!==0){        
        data.map((el,i)=>{
          currentProps.applicazioni.data=[...currentProps.applicazioni.data,el]
        })
        setDataCounter(1)
      }      
    }
  },[data])  

  /* aggiorno data, al cambio della lingua */

  useEffect(()=>{
    setDataCounter(0);
    setData([])
    currentProps.applicazioni.data=[]
  },[lingua])

  //butto i data delle nuove app/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  if(dataCounter===0){
    return(
      <div className='chargingDataPage'>
        <Loading className={darkTheme?'loadingSvgDark loadingSvg':'loadingSvg'}/>        
      </div>
    )
  }else{
    return (
      <BrowserRouter>
        <Routes>
          <Route path={currentProps.home.path} element={
            <Wrapper txtHeaderDesktop={currentProps.home.headerTxt}
                primoAccesso={primoAccesso} tour={tour} 
                clickRicercaNavbar={clickRicercaNavbar} setClickRicercaNavbar={setClickRicercaNavbar}
                filtriAttivi={filtriAttivi} darkTheme={darkTheme}
                currentPage={currentProps.home.currentPage} setCurrentPage={setCurrentPage}
                inputFocus={inputFocus} setInputFocus={setInputFocus}
                inputValue={inputValue} setInputValue={setInputValue}
                setFiltriAttivi={setFiltriAttivi}
                tile={{...currentProps.user.tiles}}>
                <Home {...currentProps.applicazioni} {...currentProps.categorie} darkTheme={darkTheme} setDark={setDarkTheme}
                  primoAccesso={primoAccesso} setPrimoAccesso={setPrimoAccesso} funzioni={{...currentProps.filtri}}
                  tour={tour} setTour={setTour}
                  txtTour={{...currentProps.tour}}
                  setLingua={setLingua} lingua={lingua}
                  propsSelect={{...currentProps.user.tiles[1]}}/>
            </Wrapper>
          }/>

          <Route path={currentProps.ricerca.path} element={
            <Wrapper txtHeaderDesktop={currentProps.home.headerTxt}
                primoAccesso={primoAccesso} tour={tour} 
                clickRicercaNavbar={clickRicercaNavbar} setClickRicercaNavbar={setClickRicercaNavbar}
                filtriAttivi={filtriAttivi}  headerPlaceholder={currentProps.ricerca.headerPlaceholder} headerReset={currentProps.ricerca.headerReset} 
                darkTheme={darkTheme}
                currentPage={currentProps.ricerca.currentPage} setCurrentPage={setCurrentPage}
                inputFocus={inputFocus} setInputFocus={setInputFocus}
                inputValue={inputValue} setInputValue={setInputValue}
                setFiltriAttivi={setFiltriAttivi}
                tile={{...currentProps.user.tiles}}>
                <Ricerca {...currentProps.applicazioni} filtri={{...currentProps.filtri}} inputFocus={inputFocus} inputValue={inputValue} darkTheme={darkTheme}
                  filtriAttivi={filtriAttivi} setFiltriAttivi={setFiltriAttivi}/>
            </Wrapper>
          }/>

          <Route path={currentProps.user.path} element={
            <Wrapper txtHeaderDesktop={currentProps.home.headerTxt}
                primoAccesso={primoAccesso} tour={tour} 
                clickRicercaNavbar={clickRicercaNavbar} setClickRicercaNavbar={setClickRicercaNavbar}
                filtriAttivi={filtriAttivi} darkTheme={darkTheme}
                currentPage={currentProps.user.currentPage} setCurrentPage={setCurrentPage}
                inputFocus={inputFocus} setInputFocus={setInputFocus}
                inputValue={inputValue} setInputValue={setInputValue}
                setFiltriAttivi={setFiltriAttivi}
                tile={{...currentProps.user.tiles}}>
                <User darkTheme={darkTheme} setDarkTheme={setDarkTheme} {...currentProps.user} setLingua={setLingua} setUnita={setUnita}
                  lingua={lingua} unita={unita}
                  tour={tour} setTour={setTour}/>
            </Wrapper>
          }/>

          <Route path={currentProps.filtri.path} element={
            <Wrapper txtHeaderDesktop={currentProps.home.headerTxt}
                primoAccesso={primoAccesso} tour={tour} 
                clickRicercaNavbar={clickRicercaNavbar} setClickRicercaNavbar={setClickRicercaNavbar}
                filtriAttivi={filtriAttivi} headerTitle={currentProps.filtri.headerTitle} headerReset={currentProps.filtri.headerReset} 
                darkTheme={darkTheme}
                currentPage={currentProps.filtri.currentPage} setCurrentPage={setCurrentPage}
                inputFocus={inputFocus} setInputFocus={setInputFocus}
                inputValue={inputValue} setInputValue={setInputValue}
                setFiltriAttivi={setFiltriAttivi}
                tile={{...currentProps.user.tiles}}>
                <Filtri app={{...currentProps.applicazioni}} filtri={{...currentProps.filtri}} darkTheme={darkTheme}
                  filtriAttivi={filtriAttivi} setFiltriAttivi={setFiltriAttivi}/>
            </Wrapper>
          }/>
          
          <Route path={currentProps.categorie.path} element={
            <Wrapper txtHeaderDesktop={currentProps.home.headerTxt}
                primoAccesso={primoAccesso} tour={tour} 
                clickRicercaNavbar={clickRicercaNavbar} setClickRicercaNavbar={setClickRicercaNavbar}
                filtriAttivi={filtriAttivi} headerTitle={currentProps.categorie}
                darkTheme={darkTheme}
                currentPage={currentProps.categorie.currentPage} setCurrentPage={setCurrentPage}
                inputFocus={inputFocus} setInputFocus={setInputFocus}
                inputValue={inputValue} setInputValue={setInputValue}
                setFiltriAttivi={setFiltriAttivi}
                tile={{...currentProps.user.tiles}}>
                <Categoria {...currentProps.applicazioni} {...currentProps.categorie} darkTheme={darkTheme} funzioni={{...currentProps.filtri}}/>
            </Wrapper>
          }/>

          <Route path={currentProps.applicazioni.path} element={          
            <Wrapper txtHeaderDesktop={currentProps.home.headerTxt}
                primoAccesso={primoAccesso} tour={tour} 
                clickRicercaNavbar={clickRicercaNavbar} setClickRicercaNavbar={setClickRicercaNavbar}
                filtriAttivi={filtriAttivi} headerTitle={currentProps.applicazioni}
                darkTheme={darkTheme}
                currentPage={currentProps.applicazioni.currentPage} setCurrentPage={setCurrentPage}
                inputFocus={inputFocus} setInputFocus={setInputFocus}
                inputValue={inputValue} setInputValue={setInputValue}
                setFiltriAttivi={setFiltriAttivi}
                tile={{...currentProps.user.tiles}}>
                <DettaglioApp app={{...currentProps.applicazioni.data}} txt={{...currentProps.applicazioni}} darkTheme={darkTheme}
                  unita={unita} chips={{...currentProps.filtri}}/>
            </Wrapper>
          }/>

          <Route path="/formSpreadSheet" element={          
            <FormSpreadSheet txtSelect={propsIta.filtri} selectNomeFasi={propsIta.applicazioni.fasi}/>
          }/>
                  
        </Routes>
      </BrowserRouter>
    );
  }
}

const mapActionToProps = {
  change: (field,fieldValue) => changeField(field,fieldValue)
}

export default connect(               
  state => { 
    return {db: state.db}
  },
  mapActionToProps
) (App)
