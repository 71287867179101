import { CHANGE_FIELD} from "./actionsType";

const initalState = {
   db:{      
      Dark: 0,
      Lingua: 0,
      Unita: 0,
      PrimoAccesso: 0,
      Tour: 0 
   }   
}

export default function (state = initalState, action) {

  var arr = JSON.parse(JSON.stringify(state.db))

   switch (action.type){
      case CHANGE_FIELD:
         arr[action.field]=action.field_value
         return{
            ...state,
            db: arr
         }

      default:
         return state
   }
}