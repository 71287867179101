import './dettaglioApp.css'
import React, { useEffect, useRef, useState } from 'react'

import { ReactComponent as Dispositivo } from './../../risorse/icone/dettaglio app/dispositivo.svg'
import { ReactComponent as Tempo } from './../../risorse/icone/dettaglio app/tempo.svg'
import { ReactComponent as Peso } from './../../risorse/icone/dettaglio app/peso.svg'
import { ReactComponent as Consumo } from './../../risorse/icone/dettaglio app/consumo.svg'

import { ReactComponent as ArrowSlideLeft } from './../../risorse/icone/ArrowSlideLeft.svg'
import { ReactComponent as ArrowSlideRight } from './../../risorse/icone/ArrowSlideRight.svg'

const DettaglioApp = (props) => {

    var dataApp = props.app[window.location.pathname.slice(14)];

    const fW = {"fontWeight":"600"};

    const transformTime = (time,tile) =>{
        var h=Math.floor(time/60);
        var min=time%60;
        var result;

        if(tile){         
            if(!h){
                result=[<span style={fW}>{min}</span>," min"];
            }else if(!min){
                result=[<span style={fW}>{h}</span>," h"]
            }else{
                result=[<span style={fW}>{h}</span>," h ",<span style={fW}>{min}</span>," min"];
            }
        }
        else{            
            if(!h){
                result=min+" min";
            }else if(!min){
                result=h+" h"
            }else{
                result=h+" h "+min+" min";
            }
        }

        return result;
    }

    const transformPeso = (pesoI,pesoF) => 
    {        
        var res= pesoF/pesoI*100
        res = res.toFixed(0)
        return res
    }

    const transformConsumo = (consumo,entrambi) => {
        var commaIndex = consumo.indexOf(';');

        var first = consumo.slice(0,commaIndex);
        var second = consumo.slice(commaIndex+1);

        first=parseFloat(first)
        second=parseFloat(second)

        if(entrambi===1){
            return first
        }else if(entrambi===2){
            return second
        }

        return (first+second).toFixed(3)      
    }

    const dividiFasiConsumo = (fasi,faseX) => {
        var commaIndex = fasi.indexOf(';');

        var first = fasi.slice(0,commaIndex);
        var second = fasi.slice(commaIndex+1);

        if(faseX===0){
            return first
        }else if(faseX===1){
            return second
        }    
    }

    const SplitCommaFasi = (fasi) => {
        fasi=fasi.split(';');
        return fasi;
    } 

    const [windowWidth, setWindowWith] = useState(window.innerWidth)

    const handleResize = () => {
        setWindowWith(window.innerWidth)
    }

    useEffect(() => {
        getIcon(dataApp.funzione);
        window.addEventListener("resize", handleResize, false);
    }, []);

    /* CALCOLO WIDTH SLIDE FUNZIONI */
    
    var numeroFasi = SplitCommaFasi(dataApp.nome_fasi).length;

    const slideFunzioni = useRef(null)    

    const [widthSlideFunzioni, setWidthSlideFunzioni] = useState(0)

    useEffect(()=>{
        if(windowWidth>1200){
            setWidthSlideFunzioni(slideFunzioni.current.clientWidth)

            if(numeroFasi*200>widthSlideFunzioni){
                setShowArrows(1)
            }else{
                setShowArrows(0)
            }
            slideFunzioni.current.scrollTo(0,0)
        }
    },[windowWidth,widthSlideFunzioni])

    const [showArrows, setShowArrows] = useState(0)

    const [greyArrow, setGreyArrow] = useState(0) //0: sinistra; 1: destra; 2: entrambe blu
    
    const [marginLeftFunzione, setMarginLeftFunzione] = useState(0)

    useEffect(()=>{
        if(marginLeftFunzione===0){
            setGreyArrow(0)
        }

        if((numeroFasi*200 + marginLeftFunzione) <= widthSlideFunzioni){
            setGreyArrow(1)
        }
    },[marginLeftFunzione])

    const deltaSlide = widthSlideFunzioni-190;

    const HandleRightArrowClick = () => {
        setMarginLeftFunzione(marginLeftFunzione-deltaSlide)
        setGreyArrow(2)
    }

    const HandleLeftArrowClick = () => {

        if(marginLeftFunzione+deltaSlide>0){
            setMarginLeftFunzione(0)
        }else{
            setMarginLeftFunzione(marginLeftFunzione+deltaSlide)
        }
        setGreyArrow(2)
    }

    /* CALCOLO WIDTH SLIDE FUNZIONI */

    const [iconaCorrente, setIconaCorrente] = useState()

    const getIcon = (txt) => {
        for(var i=0; i<props.chips.funzioni.length; i++){
            if(props.chips.funzioni[i].txt === txt){
                setIconaCorrente(props.chips.funzioni[i].icon)
            }
        }
    }

    if(windowWidth<1200){
        return(
            <div className={props.darkTheme?'dettaglioApp dettaglioAppDark':'dettaglioApp dettaglioAppLight'}>
                <div className='fotoRicettaDettaglio' style={{"backgroundImage":`url(${dataApp.img})`}}/>
                <div className='dettaglioAppBody'>

                    <div className='appFlexSezioni'>
                        <div className='appSubTitle'>{dataApp.title}</div>
                        <div className='slideChipsApp'>
                            {/* <AppChip txt={dataApp.dispositivo} dark={props.darkTheme}/>
                            <AppChip txt={dataApp.performance} dark={props.darkTheme}/> */}
                            <AppChip txt={dataApp.settore} dark={props.darkTheme}/>
                            <AppChip txt={dataApp.categoria} dark={props.darkTheme}/>
                            <AppChip txt={dataApp.funzione} dark={props.darkTheme} icon={iconaCorrente}/>
                        </div>
                    </div>

                    <div className='appFlexTileDati'>
                        <TileDatiPrincipaliApp stroke={0} dark={props.darkTheme} txt={dataApp.dispositivo} icon={Dispositivo}/>
                        {/* <TileDatiPrincipaliApp stroke={1} dark={props.darkTheme} txt={transformTime(dataApp.time,0)} icon={Tempo}/> */}
                        <TileDatiPrincipaliApp stroke={0} dark={props.darkTheme} txt={[transformPeso(dataApp.peso_iniziale,dataApp.peso_finale)," %"]} icon={Peso}/>
                        <TileDatiPrincipaliApp stroke={1} dark={props.darkTheme} txt={[transformConsumo(dataApp.consumi,0)," kWh"]} icon={Consumo}/>
                    </div>
                    
                    <div className='appFlexSezioni'>
                        <div className='appSubTitle'>{dataApp.funzione}</div>
                        <div className='slideTileFunzioneApp'>
                            {SplitCommaFasi(dataApp.nome_fasi).map((el,i)=>{
                                return <TileFunzione key={i} dark={props.darkTheme} fase={props.txt.fasi[el]}
                                    time={transformTime(SplitCommaFasi(dataApp.tempo_per_fase)[i],1)} 
                                    currentTime={SplitCommaFasi(dataApp.tempo_per_fase)[i]}
                                    totalTime={dataApp.time}
                                    unita={props.unita}/>
                            })}                        
                        </div>
                    </div>

                    <div className='appFlexSezioni'>
                        <div className='appSubTitle'>{props.txt.subTitle[0]}</div>
                        <div className='appTable'>
                            <div className='appTableRow'>
                                <div className='appTableCol'>
                                    <div className='appTableValue'>{!props.unita?dataApp.peso_iniziale:(dataApp.peso_iniziale/28.35).toFixed(0)} <span className='unitaDiMisura'>{!props.unita?"g":"oz"}</span></div>
                                    <div className='appTableDescription'>{props.txt.txtValori[0]}</div>
                                </div>

                                <div className={props.darkTheme?'rigaV rigaDark':'rigaV rigaLight'}/>

                                <div className='appTableCol'>
                                    <div className='appTableValue'>{transformPeso(dataApp.peso_iniziale,dataApp.peso_finale)} <span className='unitaDiMisura'>%</span></div>
                                    <div className='appTableDescription'>{props.txt.txtValori[1]}</div>
                                </div>

                                <div className={props.darkTheme?'rigaV rigaDark':'rigaV rigaLight'}/>
                                
                                <div className='appTableCol'>
                                    <div className='appTableValue'>{!props.unita?dataApp.peso_finale:(dataApp.peso_finale/28.35).toFixed(0)} <span className='unitaDiMisura'>{!props.unita?"g":"oz"}</span></div>
                                    <div className='appTableDescription'>{props.txt.txtValori[2]}</div>
                                </div>
                            </div>
                            <div className={props.darkTheme?'rigaO rigaDark':'rigaO rigaLight'}/>
                        </div>

                        <div className='appTable'>
                            <div className='appTableRow'>
                                <div className='appTableCol' style={{"flex":"1"}}>
                                    <div className='appTableValue'>{transformConsumo(dataApp.consumi,1)===0?<span>/</span>:transformConsumo(dataApp.consumi,1)} <span className='unitaDiMisura'>kWh</span></div>
                                    <div className='appTableDescription'>{props.txt.txtValori[3]}</div>
                                </div>

                                <div className={props.darkTheme?'rigaV rigaDark':'rigaV rigaLight'}/>
                                
                                <div className='appTableCol' style={{"flex":"1"}}>
                                    <div className='appTableValue'>{transformConsumo(dataApp.consumi,2)===0?<span>/</span>:transformConsumo(dataApp.consumi,2)} <span className='unitaDiMisura'>kWh</span></div>
                                    <div className='appTableDescription'>{props.txt.txtValori[4]}</div>
                                </div>
                            </div>
                            <div className={props.darkTheme?'rigaO rigaDark':'rigaO rigaLight'}/>
                        </div>
                    </div>

                    {dataApp.note!==undefined?<div className='appFlexSezioni'>
                        <div className='appSubTitle'>{props.txt.subTitle[1]}</div>
                        <div className='appTestoNote'>{dataApp.note}</div>
                    </div>:null}
                </div>
            </div>
        );
    }else{
        return(
            <div className={props.darkTheme?'dettaglioApp dettaglioAppDark':'dettaglioApp dettaglioAppLight'}>
                <div className='fotoRicettaDettaglio' style={{"backgroundImage":`url(${dataApp.img})`}}/>
                <div className='dettaglioAppBody'>

                    <div className='appFlexSezioni'>
                        <div className='appSubTitle'>{dataApp.title}</div>
                        <div className='slideChipsApp'>
                            {/* <AppChip txt={dataApp.dispositivo} dark={props.darkTheme}/>
                            <AppChip txt={dataApp.performance} dark={props.darkTheme}/> */}
                            <AppChip txt={dataApp.settore} dark={props.darkTheme}/>
                            <AppChip txt={dataApp.categoria} dark={props.darkTheme}/>
                            <AppChip txt={dataApp.funzione} dark={props.darkTheme} icon={iconaCorrente}/>
                        </div>
                    </div>

                    <div className='appFlexTileDati'>
                        <TileDatiPrincipaliApp stroke={0} dark={props.darkTheme} txt={dataApp.dispositivo} icon={Dispositivo}/>
                        {/* <TileDatiPrincipaliApp stroke={1} dark={props.darkTheme} txt={transformTime(dataApp.time,0)} icon={Tempo}/> */}
                        <TileDatiPrincipaliApp stroke={0} dark={props.darkTheme} txt={[transformPeso(dataApp.peso_iniziale,dataApp.peso_finale)," %"]} icon={Peso}/>
                        <TileDatiPrincipaliApp stroke={1} dark={props.darkTheme} txt={[transformConsumo(dataApp.consumi,0)," kWh"]} icon={Consumo}/>
                    </div>
                    
                    <div className='appFlexSezioni'>
                        <div className='appFlexSubtitleAndFrecce'>
                            <div className='appSubTitle'>{dataApp.funzione}</div>
                            {showArrows?<div className={props.darkTheme?'appFlexArrows appFlexArrowsDark':'appFlexArrows appFlexArrowsLight'}>
                                <ArrowSlideLeft className={greyArrow===0?"greyArrowApp":"notGreyArrowApp"} onClick={()=>{
                                    if(greyArrow!==0){
                                        HandleLeftArrowClick()
                                    }
                                }}/>
                                <ArrowSlideRight className={greyArrow===1?"greyArrowApp":"notGreyArrowApp"} onClick={()=>{
                                    if(greyArrow!==1){
                                        HandleRightArrowClick()
                                    }
                                }}/>
                            </div>:null}
                        </div>
                        

                        <div className='slideTileFunzioneApp' ref={slideFunzioni}>
                            {SplitCommaFasi(dataApp.nome_fasi).map((el,i)=>{
                                return <TileFunzione key={i} id={i} margin={marginLeftFunzione} dark={props.darkTheme} fase={props.txt.fasi[el]}
                                    time={transformTime(SplitCommaFasi(dataApp.tempo_per_fase)[i],1)} 
                                    currentTime={SplitCommaFasi(dataApp.tempo_per_fase)[i]}
                                    totalTime={dataApp.time}
                                    unita={props.unita}/>
                            })}                        
                        </div>
                    </div>

                    <div className='appFlexSezioni valueTableDesktop'>
                        <div className='appSubTitle'>{props.txt.subTitle[0]}</div>
                        <div className='appTable'>
                            <div className='appTableRow'>
                                <div className='appTableCol'>
                                    <div className='appTableValue'>{!props.unita?dataApp.peso_iniziale:(dataApp.peso_iniziale/28.35).toFixed(0)} <span className='unitaDiMisura'>{!props.unita?"g":"oz"}</span></div>
                                    <div className='appTableDescription'>{props.txt.txtValori[0]}</div>
                                </div>

                                <div className={props.darkTheme?'rigaV rigaDark':'rigaV rigaLight'}/>

                                <div className='appTableCol'>
                                    <div className='appTableValue'>{transformPeso(dataApp.peso_iniziale,dataApp.peso_finale)} <span className='unitaDiMisura'>%</span></div>
                                    <div className='appTableDescription'>{props.txt.txtValori[1]}</div>
                                </div>

                                <div className={props.darkTheme?'rigaV rigaDark':'rigaV rigaLight'}/>
                                
                                <div className='appTableCol'>
                                    <div className='appTableValue'>{!props.unita?dataApp.peso_finale:(dataApp.peso_finale/28.35).toFixed(0)} <span className='unitaDiMisura'>{!props.unita?"g":"oz"}</span></div>
                                    <div className='appTableDescription'>{props.txt.txtValori[2]}</div>
                                </div>
                            </div>
                            <div className={props.darkTheme?'rigaO rigaDark':'rigaO rigaLight'}/>
                        </div>

                        <div className='appTable'>
                            <div className='appTableRow'>
                                <div className='appTableCol' style={{"flex":"1"}}>
                                    <div className='appTableValue'>{transformConsumo(dataApp.consumi,1)===0?<span>/</span>:transformConsumo(dataApp.consumi,1)} <span className='unitaDiMisura'>kWh</span></div>
                                    <div className='appTableDescription'>{props.txt.txtValori[3]}</div>
                                </div>

                                <div className={props.darkTheme?'rigaV rigaDark':'rigaV rigaLight'}/>
                                
                                <div className='appTableCol' style={{"flex":"1"}}>
                                    <div className='appTableValue'>{transformConsumo(dataApp.consumi,2)===0?<span>/</span>:transformConsumo(dataApp.consumi,2)} <span className='unitaDiMisura'>kWh</span></div>
                                    <div className='appTableDescription'>{props.txt.txtValori[4]}</div>
                                </div>
                            </div>
                            <div className={props.darkTheme?'rigaO rigaDark':'rigaO rigaLight'}/>
                        </div>
                    </div>

                    {dataApp.note!==undefined?<div className='appFlexSezioni'>
                        <div className='appSubTitle'>{props.txt.subTitle[1]}</div>
                        <div className='appTestoNote'>{dataApp.note}</div>
                    </div>:null}
                </div>
            </div>
        );
    }
}

export default DettaglioApp;

const AppChip = (props) => {
    return(
        <div className={props.dark?'appChip appChipDark':'appChip appChipLight'}>
        {props.icon?<props.icon/>:null}
            <div style={{"width":"max-content"}}>{props.txt}</div>
            
        </div>
    );
}

const IconClass = (dark, stroke) =>{
    if(dark){
        if(stroke){
            return "appIconStrokeDark"
        }else{
            return "appIconFillDark"
        }
    }
}

const TileDatiPrincipaliApp = (props) => {
    return(
        <div className="tileDatiPrincipaliApp">
            <props.icon className={IconClass(props.dark,props.stroke)}/>
            <div className='tileDatiPrincipaliAppTxt'>{props.txt}</div>
        </div>
    );
}

const TileFunzione = ({id,fase,time,unita,dark,currentTime,totalTime,margin}) => {

    const GetPercentuale = (cT, tT) => {
        return cT/tT * 100;
    } 

    const GetDegree = () => {

        var percentuale = GetPercentuale(currentTime,totalTime);

        return percentuale*360/100;
    }

    return(
        <div className={dark?'appTileFunzione appTileFunzioneDark':'appTileFunzione appTileFunzioneLight'}
            style={id===0?{"marginLeft": `${margin}px`}:{"":""}}>

            <div className='appTileTitle'>{fase[unita].title}</div>

            <div className='appGraficoTempo' style={dark?{"backgroundImage":`conic-gradient(#8091A2 ${GetDegree()}deg, #33506A 0)`} : {"backgroundImage":`conic-gradient(#33506A ${GetDegree()}deg, #E5E9EC 0)`}}>
                <div className='cerchioTrasparenteAppTile' style={dark?{"backgroundColor":"#1D242A"}:{"backgroundColor":"#FAFBFB"}}>
                    <div className='appTileTime'>{time}</div>
                </div>
            </div>

            <div className='appTileIntervallo'>{fase[unita].intervallo}</div>
        </div>
    );
}