import './primoAccesso.css'
import React, { useEffect, useState } from 'react';

import { ReactComponent as FrecciaPiena } from './../../risorse/icone/tour/freccia_piena_grande.svg'

import { ReactComponent as Lingua } from './../../risorse/icone/profilo/lingue.svg'

import { ReactComponent as LogoIrinox } from './../../risorse/icone/logo_bianco.svg'

import { ReactComponent as Logo } from './../../risorse/icone/tour/logo_gt&g.svg'

import PopSelect from '../popup/popSelect';

const PrimoAccesso = ({txt,setLingua,lingua,propsSelect,setPrimoAccesso,setDarkTheme}) => {

    const [frame1,setFrame1] = useState(1)

    /* useEffect(()=>{
        setDarkTheme(0);
    }) */

    if(frame1){
        return <Frame1 txt={txt[0]} setFrame1={setFrame1} setLingua={setLingua} lingua={lingua} propsSelect={propsSelect} setPrimoAccesso={setPrimoAccesso}/>
    }else{
        return <Frame2 txt={txt[1]} setPrimoAccesso={setPrimoAccesso}/>
    }
        
}

export default PrimoAccesso;

const Frame1 = ({txt,setFrame1,setLingua,lingua,propsSelect,setPrimoAccesso}) => {
    
    const [popLingua, setPopLingua] = useState(0);

    return (
        <div className='primoAccesso frame1PrimoAccesso'>
            <div className='logoLinguaPrimoAccesso'>
                <LogoIrinox/>
                <div className='linguaPrimoAccesso' onClick={()=>setPopLingua(1)}>
                    <Lingua/>
                    {txt.lang}
                </div>
            </div>

            <div className='txtPrimoAccesso'>
                {txt.txt[0]}
            </div>

            <div className='titlePrimoAccesso'>
                {txt.title}
            </div>

            <div className='txtPrimoAccesso'>
                {txt.txt[1]}
            </div>
            
            <div className='iniziamoPrimoAccesso' /* onClick={()=>setFrame1(0)} per fare l'accettazione dei cookies */
                onClick={()=>setPrimoAccesso(1)}>
                {txt.btn}
                <FrecciaPiena/>
            </div>

            <div className={popLingua?'selectPopUpShow selectPopUp':'selectPopUpHidden selectPopUp'}>
                <PopSelect currentValue={lingua} darkTheme={0} setPop={setPopLingua} setLingua={setLingua} txt={propsSelect} lingua={1}/>
            </div>
            
        </div>
    )
}

const Frame2 = ({txt,setPrimoAccesso}) => {
    return (
        <div className='primoAccesso frame2PrimoAccesso'>
            <div className='popUpPrimoAccesso'>
                <div className='sopraPopUpPrimoAccesso'>                    
                    <Logo/>
                    <div className='titolettoPrimoAccesso'>
                        {txt.title}
                    </div>
                    <div className='consensoPrimoAccesso'>
                        {txt.txt}
                    </div>
                    <div className='rettangoloPopUpPrimoAccesso'/>
                </div>
                <div className='bottoniPopUpPrimoAccesso'>
                    <div className='bottonePopUpPrimoAccesso' onClick={()=>setPrimoAccesso(1)}>
                        {txt.btn1}
                    </div>
                    <div className='bottonePopUpPrimoAccesso'>
                        {txt.btn2}
                    </div>
                </div>
            </div>
        </div>
    )
}