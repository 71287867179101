import React from 'react'


import { ReactComponent as Pasticceria } from './../../risorse/icone/filtri/settore/pasticceria.svg'
import { ReactComponent as Ristorazione } from './../../risorse/icone/filtri/settore/ristorazione.svg'
import { ReactComponent as Gelateria } from './../../risorse/icone/filtri/settore/gelateria.svg'
import { ReactComponent as Panificazione } from './../../risorse/icone/filtri/settore/panificazione.svg'

import { ReactComponent as Raffreddamento } from './../../risorse/icone/filtri/funzioni/Chilling.svg'
import { ReactComponent as Surgelazione } from './../../risorse/icone/filtri/funzioni/Freezing.svg'
import { ReactComponent as NonStop } from './../../risorse/icone/filtri/funzioni/Non-stop.svg'
import { ReactComponent as ConservazioneFredda } from './../../risorse/icone/filtri/funzioni/Cold Holding.svg'
import { ReactComponent as ConservazioneCalda } from './../../risorse/icone/filtri/funzioni/Warm holding.svg'
import { ReactComponent as Scongelamento } from './../../risorse/icone/filtri/funzioni/Thawing.svg'
import { ReactComponent as ProntoVendita } from './../../risorse/icone/filtri/funzioni/Ready.svg'
import { ReactComponent as CotturaBT } from './../../risorse/icone/filtri/funzioni/Low Temperture cooking.svg'
import { ReactComponent as ScioglimentoCioccolato } from './../../risorse/icone/filtri/funzioni/Chocolate.svg'
import { ReactComponent as LievitazioneDiretta } from './../../risorse/icone/filtri/funzioni/Proofing.svg'
import { ReactComponent as LievitazioneProgrammata } from './../../risorse/icone/filtri/funzioni/Planned prrofing.svg'
import { ReactComponent as FermaLievitazione } from './../../risorse/icone/filtri/funzioni/Retarder-proofing.svg'
import { ReactComponent as Pastorizzazione } from './../../risorse/icone/filtri/funzioni/Pasteurization.svg'
import { ReactComponent as Essiccazione } from './../../risorse/icone/filtri/funzioni/Drying.svg'

const fW = {"fontWeight":"600"};


const propsGer = {
    home: {
        path: "/",
        currentPage: 0,
        headerTxt: [
          "Heim",
          "Profil"
        ]
    },
    ricerca: {
        path: "/ricerca",
        headerPlaceholder: "Suche",
        headerReset: "Zurücksetzen",
        currentPage: 1,
    },
    user: {
        path: "/user",
        currentPage: 2,
        tiles: [
            {
                title: "Einheit",
                options: [
                    {
                        title: "Metrisches system",
                        txt: "kg, °C"
                    },
                    {
                        title: "Imperiales system",
                        txt: "lb, °F"
                    },
                ],
                btnSalva: "SPAREN",
                btnAnnulla: "ABSAGEN"
            },
            {
                title: "Sprache",
                options: [
                    {
                        title: "Italienisch",
                        txt: "Italiano"
                    },
                    {
                        title: "Englisch",
                        txt: "English"
                    },
                    {
                        title: "Spanisch",
                        txt: "Español"
                    },
                    {
                        title: "Französisch",
                        txt: "Français"
                    },
                    {
                        title: "Deutsch",
                        txt: "Deutsch"
                    },
                ],
                btnSalva: "SPAREN",
                btnAnnulla: "ABSAGEN"
            },
            {
                title: "Dunkler Modus",                
            },
            {
                title: "Wiederholen sie die app-tour",                
            },
            {
                title: "Teilen",
                share: "Teilen mit",
                copia: "Kopieren",
                annulla: "Absagen",              
            },
            {
                title: "Über Irinox",                
            }
        ]
    },
    filtri: {
        path: "/filtri",
        headerTitle: "Filter",
        headerReset: "Zurücksetzen",
        currentPage: 3,
        btn: "Anwendungen anzeigen",
        titleSettori: "Sektoren",
        titleCategorie: "Kategorien",
        titleFunzioni: "Funktionen",
        settori: [
          {
            txt: "Konditorei",
            icon: Pasticceria,
          },
          {
            txt: "Gastronomie",
            icon: Ristorazione,
          },
          {
            txt: "Speiseeisbereitung",
            icon: Gelateria,
          },
          {
            txt: "Brotbereitung",
            icon: Panificazione,
          },
        ],
        funzioni: [
          {
            txt: "Schnellkühlen",
            icon: Raffreddamento,
          },
          {
            txt: "Schockfrosten",
            icon: Surgelazione,
          },
          {
            txt: "Non-Stop",
            icon: NonStop,
          },
          {
            txt: "Kalthalten",
            icon: ConservazioneFredda,
          },
          {
            txt: "Warmhalten",
            icon: ConservazioneCalda,
          },
          {
            txt: "Auftauen",
            icon: Scongelamento,
          },
          {
            txt: "Servierbereit",
            icon: ProntoVendita,
          },
          {
            txt: "Niedertemperatur-garen",
            icon: CotturaBT,
          },
          {
            txt: "Schokolade schmelzen",
            icon: ScioglimentoCioccolato,
          },
          {
            txt: "Direkte gärung",
            icon: LievitazioneDiretta,
          },
          {
            txt: "Programmierte gärung",
            icon: LievitazioneProgrammata,
          },
          {
            txt: "Verzögerte gärung",
            icon: FermaLievitazione,
          },
          {
            txt: "Pasteurisieren",
            icon: Pastorizzazione,
          },
          {
            txt: "Trocknen",
            icon: Essiccazione,
          }
        ],
        categorie: [
          {
            txt: "Fleisch",
            icon: "",
          },
          {
            txt: "Gemüse",
            icon: "",
          },
          {
            txt: "Nudeln/Reis",
            icon: "",
          },
          {
            txt: "Salsas/Soßen/Suppen",
            icon: "",
          },
          {
            txt: "Kuchen",
            icon: "",
          },
          {
            txt: "Brot",
            icon: "",
          },
          {
            txt: "Früchte",
            icon: "",
          },
          {
            txt: "Eis/Sorbets",
            icon: "",
          },
        ]
    }, 
    categorie: {
        path: "/categorie/:catid",
        currentPage: 4,
        nomi: [
            {
                id: 0,
                headerTitle: "Fleisch",
            },   
            {
                id: 1,
                headerTitle: "Gemüse",
            },
            {
                id: 2,
                headerTitle: "Nudeln/Reis",
            },
            {
                id: 3,
                headerTitle: "Salsas/Soßen/Suppen",
            },
            {
                id: 4,
                headerTitle: "Kuchen",
            },
            {
                id: 5,
                headerTitle: "Brot",
            },
            {
                id: 6,
                headerTitle: "Früchte",
            },
            {
                id: 7,
                headerTitle: "Eis/Sorbets",
            }, 
        ],
    },   
    applicazioni: {
        path: "/applicazioni/:appid",
        currentPage: 4,
        altro: "Sonstiges", 
        data: [],
        subTitle: [
          "Werte",
          "Anmerkungen"
        ],
        txtValori: [
          "ANFANGSGEWICHT",
          "LETZTER PROZENTSATZ",
          "ENDGÜLTIGES GEWICHT",
          "ENERGIEVERBRAUCH Schnellkühlung",
          "ENERGIEVERBRAUCH Schockgefrieren"
        ],
        fasi: [
          [
            {
              title: "SCHNELLKÜHLEN",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "SCHNELLKÜHLEN",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "SCHNELLKÜHLEN",
              intervallo: [<span style={fW}>+10</span>,"°C"],
            },
            {
              title: "SCHNELLKÜHLEN",
              intervallo: [<span style={fW}>+50</span>,"°F"],
            }
          ],
          [
            {
              title: "SCHNELLKÜHLEN",
              intervallo: [<span style={fW}>+12</span>,"°C"],
            },
            {
              title: "SCHNELLKÜHLEN",
              intervallo: [<span style={fW}>+53,6</span>,"°F"],
            }
          ],
          [
            {
              title: "SCHNELLKÜHLEN",
              intervallo: [<span style={fW}>+25</span>,"°C"],
            },
            {
              title: "SCHNELLKÜHLEN",
              intervallo: [<span style={fW}>+77</span>,"°F"],
            }
          ],
          [
            {
              title: "SCHOCKFROSTEN",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "SCHOCKFROSTEN",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "SCHOCKFROSTEN",
              intervallo: [<span style={fW}>-12</span>,"°C"],
            },
            {
              title: "SCHOCKFROSTEN",
              intervallo: [<span style={fW}>+10,4</span>,"°F"],
            }
          ],
          [
            {
              title: "NON-STOP",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "NON-STOP",            
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "NON-STOP",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "NON-STOP",            
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "KALTHALTEN",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "KALTHALTEN",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "KALTHALTEN",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "KALTHALTEN",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "WARMHALTEN",
              intervallo: [<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "WARMHALTEN",
              intervallo: [<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "AUFTAUEN",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "AUFTAUEN",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "SERVIERBEREIT",
              intervallo: ["da ",<span style={fW}>-18</span>," a ",<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "SERVIERBEREIT",
              intervallo: ["da ",<span style={fW}>-0,4</span>," a ",<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "SERVIERBEREIT",
              intervallo: ["da ",<span style={fW}>+3</span>," a ",<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "SERVIERBEREIT",
              intervallo: ["da ",<span style={fW}>+37,4</span>," a ",<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "NIEDERTEMPERATUR-GAREN",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "NIEDERTEMPERATUR-GAREN",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "NIEDERTEMPERATUR-GAREN",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "NIEDERTEMPERATUR-GAREN",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "NIEDERTEMPERATUR-GAREN",
              intervallo: [<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "NIEDERTEMPERATUR-GAREN",
              intervallo: [<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "SCHOKOLADE SCHMELZEN",
              intervallo: [<span style={fW}>+45</span>,"°C"],
            },
            {
              title: "SCHOKOLADE SCHMELZEN",
              intervallo: [<span style={fW}>+113</span>,"°F"],
            }
          ],
          [
            {
              title: "DIREKTE GÄRUNG",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "DIREKTE GÄRUNG",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "DIREKTE GÄRUNG",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "DIREKTE GÄRUNG",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "DIREKTE GÄRUNG",
              intervallo: [<span style={fW}>+30</span>,"°C"],
            },
            {
              title: "DIREKTE GÄRUNG",
              intervallo: [<span style={fW}>+86</span>,"°F"],
            }
          ],
          [
            {
              title: "PROGRAMMIERTE GÄRUNG",
              intervallo: ["da ",<span style={fW}>-18</span>," a ",<span style={fW}>+10</span>,"°C"],
            },
            {
              title: "PROGRAMMIERTE GÄRUNG",
              intervallo: ["da ",<span style={fW}>-0,4</span>," a ",<span style={fW}>+50</span>,"°F"],
            }
          ],
          [
            {
              title: "PROGRAMMIERTE GÄRUNG",
              intervallo: ["da ",<span style={fW}>+3</span>," a ",<span style={fW}>+10</span>,"°C"],
            },
            {
              title: "PROGRAMMIERTE GÄRUNG",
              intervallo: ["da ",<span style={fW}>+37,4</span>," a ",<span style={fW}>+50</span>,"°F"],
            }
          ],
          [
            {
              title: "VERZÖGERTE GÄRUNG",
              intervallo: "",
            },
            {
              title: "VERZÖGERTE GÄRUNG",
              intervallo: "",
            }
          ],
          [
            {
              title: "PASTEURISIEREN",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "PASTEURISIEREN",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "PASTEURISIEREN",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "PASTEURISIEREN",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "TROCKNEN",
              intervallo: "",
            },
            {
              title: "TROCKNEN",
              intervallo: "",
            }
          ],
        ]
    },
    tour: {
      primoAccesso: [
        {
          lang: "DE",
          title: "Richtzeiten und Temperaturen",
          txt: [
            "Welcome to",
            "Holen Sie das Beste aus Ihrem MF Next mit dem Zeit- und Temperaturleitfaden heraus, der von unseren Köchen entworfen wurde"
          ],
          btn: "Lasst uns beginnen!",

        },
        {
          title: "Guida tempi e temperature richiede il tuo consenso per utilizzare i tuoi dati personali per:",
          txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nec sollicitudin diam. Suspendisse eget orci sagittis, porta dui sed, vestibulum est. Cras mollis interdum nisl, vitae ullamcorper turpis aliquam et. Suspendisse vulputate, metus ut ultrices congue, augue augue sollicitudin arcu, consectetur hendrerit sapien ipsum ac orci. Nam viverra semper lectus ut pulvinar. Nulla a leo vel risus vestibulum mattis. Etiam ut enim massa. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam blandit accumsan nunc, vitae lobortis justo finibus eget. Pellentesque vestibulum molestie justo, et iaculis augue feugiat sed.",
          btn1: "Acconsento",
          btn2: "Gestisci le opzioni"
        }
      ],
      tour:{
        schermoBlu: [
          {
            txt: "Dies ist ein kurzes tutorial, das sie durch die navigation in ihrer web-app führt.",
            btn: "Lernprogramm starten!",
            salta: "ÜBERSPRINGEN"
          },
          {
            txt: "Das Tutorial ist beendet. Wünschen Ihnen eine gute Zeit!",
            btn: "Gehen sie zur startseite!",
          }
        ],
        tour: [
          {
            txt: "Dies ist die navigationsleiste. Die navbar ist ein fester bestandteil, den sie auf fast jeder seite der web-app finden.",
            btn: "Fortsetzen",
            salta: "ÜBERSPRINGEN"
          },
          {
            txt: "Das ist der home-button. Jedes mal, wenn sie darauf drücken, gelangen sie zurück zur startseite.",
            btn: "Fortsetzen",
            salta: "ÜBERSPRINGEN"
          },
          {
            txt: "Dies ist die suchschaltfläche. Jedes mal, wenn sie darauf drücken, gelangen sie zur rezeptbuchseite und sie können nach ihrer anwendung suchen.",
            btn: "Fortsetzen",
            salta: "ÜBERSPRINGEN"
          },
          {
            txt: "Dies ist die profilschaltfläche. Jedes mal, wenn sie darauf drücken, gelangen sie zur profilseite.",
            btn: "Fortsetzen",
            salta: "ÜBERSPRINGEN"
          },
        ]
      }
    }     
}
export default propsGer;