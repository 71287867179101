import React from 'react'


import { ReactComponent as Pasticceria } from './../../risorse/icone/filtri/settore/pasticceria.svg'
import { ReactComponent as Ristorazione } from './../../risorse/icone/filtri/settore/ristorazione.svg'
import { ReactComponent as Gelateria } from './../../risorse/icone/filtri/settore/gelateria.svg'
import { ReactComponent as Panificazione } from './../../risorse/icone/filtri/settore/panificazione.svg'

import { ReactComponent as Raffreddamento } from './../../risorse/icone/filtri/funzioni/Chilling.svg'
import { ReactComponent as Surgelazione } from './../../risorse/icone/filtri/funzioni/Freezing.svg'
import { ReactComponent as NonStop } from './../../risorse/icone/filtri/funzioni/Non-stop.svg'
import { ReactComponent as ConservazioneFredda } from './../../risorse/icone/filtri/funzioni/Cold Holding.svg'
import { ReactComponent as ConservazioneCalda } from './../../risorse/icone/filtri/funzioni/Warm holding.svg'
import { ReactComponent as Scongelamento } from './../../risorse/icone/filtri/funzioni/Thawing.svg'
import { ReactComponent as ProntoVendita } from './../../risorse/icone/filtri/funzioni/Ready.svg'
import { ReactComponent as CotturaBT } from './../../risorse/icone/filtri/funzioni/Low Temperture cooking.svg'
import { ReactComponent as ScioglimentoCioccolato } from './../../risorse/icone/filtri/funzioni/Chocolate.svg'
import { ReactComponent as LievitazioneDiretta } from './../../risorse/icone/filtri/funzioni/Proofing.svg'
import { ReactComponent as LievitazioneProgrammata } from './../../risorse/icone/filtri/funzioni/Planned prrofing.svg'
import { ReactComponent as FermaLievitazione } from './../../risorse/icone/filtri/funzioni/Retarder-proofing.svg'
import { ReactComponent as Pastorizzazione } from './../../risorse/icone/filtri/funzioni/Pasteurization.svg'
import { ReactComponent as Essiccazione } from './../../risorse/icone/filtri/funzioni/Drying.svg'

const fW = {"fontWeight":"600"};


const propsSpa = {
    home: {
        path: "/",
        currentPage: 0,
        headerTxt: [
          "Página principal",
          "Perfil"
        ]
    },
    ricerca: {
        path: "/ricerca",
        headerPlaceholder: "Cerca",
        headerReset: "Reiniciar",
        currentPage: 1,
    },
    user: {
        path: "/user",
        currentPage: 2,
        tiles: [
            {
                title: "Unidad",
                options: [
                    {
                        title: "Sistema métrico",
                        txt: "kg, °C"
                    },
                    {
                        title: "Sistema imperial",
                        txt: "lb, °F"
                    },
                ],
                btnSalva: "AHORRAR",
                btnAnnulla: "CANCELAR"
            },
            {
                title: "Lengua",
                options: [
                    {
                        title: "Italiano",
                        txt: "Italiano"
                    },
                    {
                        title: "Inglés",
                        txt: "English"
                    },
                    {
                        title: "Español",
                        txt: "Español"
                    },
                    {
                        title: "Francés",
                        txt: "Français"
                    },
                    {
                        title: "Alemán",
                        txt: "Deutsch"
                    },
                ],
                btnSalva: "AHORRAR",
                btnAnnulla: "CANCELAR"
            },
            {
                title: "Modo oscuro",                
            },
            {
                title: "Repetir recorrido por la aplicación",                
            },
            {
                title: "Cuota",
                share: "Compartir con",
                copia: "Copiar",
                annulla: "Cancelar",              
            },
            {
                title: "Acerca de Irinox",                
            }
        ]
    },
    filtri: {
        path: "/filtri",
        headerTitle: "Filtros",
        headerReset: "Reiniciar",
        currentPage: 3,
        btn: "Ver aplicaciones",
        titleSettori: "Sectores",
        titleCategorie: "Categorías",
        titleFunzioni: "Funciones",
        settori: [
          {
            txt: "Pastelería",
            icon: Pasticceria,
          },
          {
            txt: "Restauración",
            icon: Ristorazione,
          },
          {
            txt: "Heladería",
            icon: Gelateria,
          },
          {
            txt: "Panificación",
            icon: Panificazione,
          },
        ],
        funzioni: [
          {
            txt: "Enfriamiento",
            icon: Raffreddamento,
          },
          {
            txt: "Congelación",
            icon: Surgelazione,
          },
          {
            txt: "Non-Stop",
            icon: NonStop,
          },
          {
            txt: "Conservación en frío",
            icon: ConservazioneFredda,
          },
          {
            txt: "Conservación en caliente",
            icon: ConservazioneCalda,
          },
          {
            txt: "Descongelación",
            icon: Scongelamento,
          },
          {
            txt: "Listo para servir",
            icon: ProntoVendita,
          },
          {
            txt: "Cocción a baja temperatura",
            icon: CotturaBT,
          },
          {
            txt: "Derretimiento del chocolate",
            icon: ScioglimentoCioccolato,
          },
          {
            txt: "Fermentación directa",
            icon: LievitazioneDiretta,
          },
          {
            txt: "Fermentación programada",
            icon: LievitazioneProgrammata,
          },
          {
            txt: "Fermentación controlada",
            icon: FermaLievitazione,
          },
          {
            txt: "Pasteurización",
            icon: Pastorizzazione,
          },
          {
            txt: "Deshidratación",
            icon: Essiccazione,
          }
        ],
        categorie: [
          {
            txt: "Carne",
            icon: "",
          },
          {
            txt: "Verduras",
            icon: "",
          },
          {
            txt: "Pastas/Arroz",
            icon: "",
          },
          {
            txt: "Salsas/Sopas",
            icon: "",
          },
          {
            txt: "Tortas",
            icon: "",
          },
          {
            txt: "Pan de molde",
            icon: "",
          },
          {
            txt: "Fruta",
            icon: "",
          },
          {
            txt: "Helados/Sorbetes",
            icon: "",
          },
        ]
    }, 
    categorie: {
        path: "/categorie/:catid",
        currentPage: 4,
        nomi: [
            {
                id: 0,
                headerTitle: "Carne",
            },   
            {
                id: 1,
                headerTitle: "Verduras",
            },
            {
                id: 2,
                headerTitle: "Pastas/Arroz",
            },
            {
                id: 3,
                headerTitle: "Salsas/Sopas",
            },
            {
                id: 4,
                headerTitle: "Tortas",
            },
            {
                id: 5,
                headerTitle: "Pan de molde",
            },
            {
                id: 6,
                headerTitle: "Fruta",
            },
            {
                id: 7,
                headerTitle: "Helados/Sorbetes",
            }, 
        ],
    },   
    applicazioni: {
        path: "/applicazioni/:appid",
        currentPage: 4,
        altro: "Otro", 
        data: [],
        subTitle: [
          "Valores",
          "Nota"
        ],
        txtValori: [
          "PESO INICIAL",
          "PORCENTAJE FINAL",
          "PESO FINAL",
          "CONSUMO DE ENERGÍA Refrigeración",
          "CONSUMO DE ENERGÍA Congelación"
        ],
        fasi: [
          [
            {
              title: "ENFRIAMIENTO",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "ENFRIAMIENTO",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "ENFRIAMIENTO",
              intervallo: [<span style={fW}>+10</span>,"°C"],
            },
            {
              title: "ENFRIAMIENTO",
              intervallo: [<span style={fW}>+50</span>,"°F"],
            }
          ],
          [
            {
              title: "ENFRIAMIENTO",
              intervallo: [<span style={fW}>+12</span>,"°C"],
            },
            {
              title: "ENFRIAMIENTO",
              intervallo: [<span style={fW}>+53,6</span>,"°F"],
            }
          ],
          [
            {
              title: "ENFRIAMIENTO",
              intervallo: [<span style={fW}>+25</span>,"°C"],
            },
            {
              title: "ENFRIAMIENTO",
              intervallo: [<span style={fW}>+77</span>,"°F"],
            }
          ],
          [
            {
              title: "CONGELACIÓN",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "CONGELACIÓN",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "CONGELACIÓN",
              intervallo: [<span style={fW}>-12</span>,"°C"],
            },
            {
              title: "CONGELACIÓN",
              intervallo: [<span style={fW}>+10,4</span>,"°F"],
            }
          ],
          [
            {
              title: "NON-STOP",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "NON-STOP",            
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "NON-STOP",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "NON-STOP",            
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "CONSERVACIÓN EN FRÍO",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "CONSERVACIÓN EN FRÍO",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "CONSERVACIÓN EN FRÍO",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "CONSERVACIÓN EN FRÍO",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "CONSERVACIÓN EN CALIENTE",
              intervallo: [<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "CONSERVACIÓN EN CALIENTE",
              intervallo: [<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "DESCONGELACIÓN",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "DESCONGELACIÓN",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "LISTO PARA SERVIR",
              intervallo: ["da ",<span style={fW}>-18</span>," a ",<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "LISTO PARA SERVIR",
              intervallo: ["da ",<span style={fW}>-0,4</span>," a ",<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "LISTO PARA SERVIR",
              intervallo: ["da ",<span style={fW}>+3</span>," a ",<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "LISTO PARA SERVIR",
              intervallo: ["da ",<span style={fW}>+37,4</span>," a ",<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "COCCIÓN A BAJA TEMPERATURA",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "COCCIÓN A BAJA TEMPERATURA",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "COCCIÓN A BAJA TEMPERATURA",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "COCCIÓN A BAJA TEMPERATURA",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "COCCIÓN A BAJA TEMPERATURA",
              intervallo: [<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "COCCIÓN A BAJA TEMPERATURA",
              intervallo: [<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "DERRETIMIENTO DEL CHOCOLATE",
              intervallo: [<span style={fW}>+45</span>,"°C"],
            },
            {
              title: "DERRETIMIENTO DEL CHOCOLATE",
              intervallo: [<span style={fW}>+113</span>,"°F"],
            }
          ],
          [
            {
              title: "FERMENTACIÓN DIRECTA",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "FERMENTACIÓN DIRECTA",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "FERMENTACIÓN DIRECTA",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "FERMENTACIÓN DIRECTA",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "FERMENTACIÓN DIRECTA",
              intervallo: [<span style={fW}>+30</span>,"°C"],
            },
            {
              title: "FERMENTACIÓN DIRECTA",
              intervallo: [<span style={fW}>+86</span>,"°F"],
            }
          ],
          [
            {
              title: "FERMENTACIÓN PROGRAMADA",
              intervallo: ["da ",<span style={fW}>-18</span>," a ",<span style={fW}>+10</span>,"°C"],
            },
            {
              title: "FERMENTACIÓN PROGRAMADA",
              intervallo: ["da ",<span style={fW}>-0,4</span>," a ",<span style={fW}>+50</span>,"°F"],
            }
          ],
          [
            {
              title: "FERMENTACIÓN PROGRAMADA",
              intervallo: ["da ",<span style={fW}>+3</span>," a ",<span style={fW}>+10</span>,"°C"],
            },
            {
              title: "FERMENTACIÓN PROGRAMADA",
              intervallo: ["da ",<span style={fW}>+37,4</span>," a ",<span style={fW}>+50</span>,"°F"],
            }
          ],
          [
            {
              title: "FERMENTACIÓN CONTROLADA",
              intervallo: "",
            },
            {
              title: "FERMENTACIÓN CONTROLADA",
              intervallo: "",
            }
          ],
          [
            {
              title: "PASTEURIZACIÓN",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "PASTEURIZACIÓN",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "PASTEURIZACIÓN",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "PASTEURIZACIÓN",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "DESHIDRATACIÓN",
              intervallo: "",
            },
            {
              title: "DESHIDRATACIÓN",
              intervallo: "",
            }
          ],
        ]
    },
    tour: {
      primoAccesso: [
        {
          lang: "ES",
          title: "Guía de tiempos y temperaturas",
          txt: [
            "Bienvenido a",
            "¡Aprovecha al máximo tu MF Next con la Guía de tiempos y temperaturas diseñada por nuestros chefs!"
          ],
          btn: "¡Vamos a empezar!",

        },
        {
          title: "Guida tempi e temperature richiede il tuo consenso per utilizzare i tuoi dati personali per:",
          txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nec sollicitudin diam. Suspendisse eget orci sagittis, porta dui sed, vestibulum est. Cras mollis interdum nisl, vitae ullamcorper turpis aliquam et. Suspendisse vulputate, metus ut ultrices congue, augue augue sollicitudin arcu, consectetur hendrerit sapien ipsum ac orci. Nam viverra semper lectus ut pulvinar. Nulla a leo vel risus vestibulum mattis. Etiam ut enim massa. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam blandit accumsan nunc, vitae lobortis justo finibus eget. Pellentesque vestibulum molestie justo, et iaculis augue feugiat sed.",
          btn1: "Acconsento",
          btn2: "Gestisci le opzioni"
        }
      ],
      tour:{
        schermoBlu: [
          {
            txt: "Este es un breve tutorial que te guiará en la navegación de tu aplicación web.",
            btn: "¡Empieza el tutorial!",
            salta: "SALTAR"
          },
          {
            txt: "El tutorial se ha terminado. ¡Disfruta!",
            btn: "¡Regresa a la pantalla de inicio!",
          }
        ],
        tour: [
          {
            txt: "Esta es la barra de navigación. La barra de navigación es un componente fijo que encontrarás en casi todas las pantallas de la aplicación.",
            btn: "Continuar",
            salta: "SALTAR"
          },
          {
            txt: "Este es el botón de inicio. Cada vez que lo pulses te llevará de regreso a la pantalla de inicio.",
            btn: "Continuar",
            salta: "SALTAR"
          },
          {
            txt: "Este es el botón de búsqueda. Cada vez que lo pulses, te llevará a la pantalla de recetas y podrás buscar tu aplicación.",
            btn: "Continuar",
            salta: "SALTAR"
          },
          {
            txt: "Este es el botón de perfil. Cada vez que lo pulses te llevará a la pantalla del perfil.",
            btn: "Continuar",
            salta: "SALTAR"
          },
        ]
      }
    }     
}
export default propsSpa;