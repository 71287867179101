import './tileProfilo.css'
import React from 'react';
import { useNavigate } from 'react-router-dom';


const TileProfilo = (props) => {
    
    const navigate = useNavigate();

    if(props.unita){
        return(
            <div className={props.darkTheme?'tileProfilo tileProfiloDark iconaProfiloDarkfill':'tileProfilo tileProfiloLight iconaProfiloLightfill'}
                onClick={()=>props.setPopUnita(1)}>
                <div className='frameTileProfilo'>
                    <props.icona/>
                    <span className='titleTileProfilo'>{props.title}</span>
                </div>
            </div>
        );
    }else if(props.lingua){
        return(
            <div className={props.darkTheme?'tileProfilo tileProfiloDark iconaProfiloDarkfill':'tileProfilo tileProfiloLight iconaProfiloLightfill'}
                onClick={()=>props.setPopLingua(1)}>
                <div className='frameTileProfilo'>
                    <props.icona/>
                    <span className='titleTileProfilo'>{props.title}</span>
                </div>
            </div>
        );
    }else if(props.display){
        return(
            <div className={props.darkTheme?'tileProfilo tileProfiloDark iconaProfiloDarkfill':'tileProfilo tileProfiloLight iconaProfiloLightfill'}
                style={{"justifyContent":"space-between"}}
                onClick={()=>{
                    props.setDarkTheme(props.darkTheme?0:1)
                }}>
                <div className='frameTileProfilo'>
                    <props.icona className={props.darkTheme?
                        props.stroke?'iconaProfiloDarkStroke':'iconaProfiloDarkfill'
                        :props.stroke?'iconaProfiloLightStroke':'iconaProfiloLightfill'}/>
                    <span className='titleTileProfilo'>{props.title}</span>
                </div>
                <div className='contenitoreToggleProfilo'>
                    <div className={props.darkTheme?'rettangoloToggle rettangoloToggleDark':'rettangoloToggle rettangoloToggleLight'}>
                        <div className={props.darkTheme?'cerchioToggle cerchioToggleDark':'cerchioToggle cerchioToggleLight'}/>
                    </div>
                </div>
            </div>
        );
    }else if(props.help){
        return(
            <div className={props.darkTheme?'tileProfilo tileProfiloDark iconaProfiloDarkStroke':'tileProfilo tileProfiloLight iconaProfiloLightStroke'}
                onClick={()=>{
                    props.setTour(0);
                    navigate("/")
                }}>
                <div className='frameTileProfilo'>
                    <props.icona/>
                    <span className='titleTileProfilo'>{props.title}</span>
                </div>
            </div>
        );
    }else if(props.share){
        return(
            <div className={props.darkTheme?'tileProfilo tileProfiloDark iconaProfiloDarkfill':'tileProfilo tileProfiloLight iconaProfiloLightfill'}
                    onClick={()=>{
                        /* if (navigator.share) {   solo con https
                            navigator.share({
                              title: 'web.dev',
                              text: 'Check out web.dev.',
                              url: 'https://web.dev/',
                            })
                              .then(() => console.log('Successful share'))
                              .catch((error) => console.log('Error sharing', error));
                          } */
                        props.setPopShare(1)
                    }}>
                <div className='frameTileProfilo'>
                    <props.icona/>
                    <span className='titleTileProfilo'>{props.title}</span>
                </div>
            </div>
        );
    }else if(props.info){
        return(
            <a href='https://www.irinoxprofessional.com/ita/azienda' target="_blank">
                <div className={props.darkTheme?'tileProfilo tileProfiloDark iconaProfiloDarkStroke':'tileProfilo tileProfiloLight iconaProfiloLightStroke'}>
                    <div className='frameTileProfilo'>
                        <props.icona/>
                        <span className='titleTileProfilo'>{props.title}</span>
                    </div>
                </div>
            </a>
        );
    }
}

export default TileProfilo;