import { useNavigate } from 'react-router-dom';
import React,{ useEffect, useState } from 'react';
import Chips from '../chips/chips';
import CheckFiltri from '../functions/checkFiltri';
import './filtri.css'

const Filtri = (props) => {

    const navigate = useNavigate();

    const [currentFilters, setCurrentFilters] = useState([...props.filtriAttivi])

    useEffect(()=>{
        setCurrentFilters([...props.filtriAttivi])
    }, [props.filtriAttivi])

    const HandleClick = (txt,icon) => {

        var exist = false;
        var index;

        currentFilters.map(
            (el,i) => {
                if (txt===el.txt){
                    exist=true;
                    index=i;
                }
            }
        )

        var newFiltro= {
            txt: txt,
            icon: icon
        }
        
        var arr=[...currentFilters]

        if (!exist){
            setCurrentFilters([...currentFilters,newFiltro])
        }
        else{
            arr.splice(index,1)
            setCurrentFilters([...arr])
        }
    }

    var attivo;

    const getNumber = (app,filtri) => {
        var contatore=0;
        if(filtri.length!==0)
        {
            app.map(
                (el,i) => {
                if(CheckFiltri(filtri,el.categoria,el.settore,el.funzione)){
                    contatore++;
                } 
                }
            )
            return contatore;
        }else{
            return 0;
        }
    }

    const CreateTitle = (txt) => {
        txt = txt.split(' ');

        var newTxt = [txt[0] , " ", getNumber(props.app.data,currentFilters), " ", txt[1]]

        return newTxt;
    }

    const AddFiltri = () => {
        props.setFiltriAttivi([...currentFilters])
        navigate("/ricerca")
    }

    return(
        <div className='filtri'>            
            <div className='sezioneFiltro'>
                <span className={props.darkTheme?'titoloSezioneFiltro titoloSezioneFiltroDark':'titoloSezioneFiltro titoloSezioneFiltroLight'}>{props.filtri.titleSettori}</span>
                <div className='slideFiltriFiltro'>    
                    {props.filtri.settori.map(
                        (el,i) => {               
                            attivo=false;
                            currentFilters.map(
                                (filtro,y)=>{
                                    if(el.txt===filtro.txt)
                                    {
                                        attivo=true;
                                    }
                                }
                            )
                            return <Chips key={i} onClick={()=>HandleClick(el.txt,el.icon)} darkTheme={props.darkTheme}
                                        cross={0} selected={attivo?1:0} txt={el.txt} icon={el.icon}/>
                        }
                    )}                
                </div>
            </div>

            <div className='sezioneFiltro'>
                <span className={props.darkTheme?'titoloSezioneFiltro titoloSezioneFiltroDark':'titoloSezioneFiltro titoloSezioneFiltroLight'}>{props.filtri.titleCategorie}</span>
                <div className='slideFiltriFiltro'>    
                    {props.filtri.categorie.map(
                        (el,i) => {               
                            attivo=false;
                            currentFilters.map(
                                (filtro,y)=>{
                                    if(el.txt===filtro.txt)
                                    {
                                        attivo=true;
                                    }
                                }
                            )
                            return <Chips key={i} onClick={()=>HandleClick(el.txt,el.icon)} darkTheme={props.darkTheme}
                                        cross={0} selected={attivo?1:0} txt={el.txt} icon={el.icon}/>
                        }
                    )}              
                </div>
            </div>

            <div className='sezioneFiltro'>
                <span className={props.darkTheme?'titoloSezioneFiltro titoloSezioneFiltroDark':'titoloSezioneFiltro titoloSezioneFiltroLight'}>{props.filtri.titleFunzioni}</span>
                <div className='slideFiltriFiltro'>    
                    {props.filtri.funzioni.map(
                        (el,i) => {               
                            attivo=false;
                            currentFilters.map(
                                (filtro,y)=>{
                                    if(el.txt===filtro.txt)
                                    {
                                        attivo=true;
                                    }
                                }
                            )
                            return <Chips key={i} onClick={()=>HandleClick(el.txt,el.icon)} darkTheme={props.darkTheme}
                                        cross={0} selected={attivo?1:0} txt={el.txt} icon={el.icon}/>
                        }
                    )}               
                </div>
            </div>

            {currentFilters.length!==0?
            <div className={props.darkTheme?'chipDarkSelected vediAppFiltrare':'chipLightSelected vediAppFiltrare'}
                onClick={()=>AddFiltri()}>
                {CreateTitle(props.filtri.btn)}
            </div>:null}
        </div>
    );
}

export default Filtri;

/* 
<Chips onClick={()=>HandleClick(i)} darkTheme={props.darkTheme} cross={1} selected={0} txt={el.txt} icon={el.icon}/>
*/