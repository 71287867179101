import { useNavigate } from 'react-router-dom';
import RicettaHome from '../ricetta/ricettaHome';
import './home.css'
import React from 'react';


import { ReactComponent as Freccia } from './../../risorse/icone/FrecciaAltro.svg'
import Tour from '../tour/tour';
import PrimoAccesso from '../tour/primoAccesso';

const Home = (props) => {
    const navigate = useNavigate();

    const transformTime = (time) =>{
        var h=Math.floor(time/60);
        var min=time%60;
        var result;
        if(!h){
            result=min+" min";
        }else if(!min){
            result=h+" h"
        }else{
            result=h+" h "+min+" min";
        }
        return result;
    }

    var counter;

    const getAppNumber = (categoria,appArray) =>{
        var c=0;
        appArray.map(
            (el,i) => {
                if(appArray[i].categoria===categoria)
                {
                    c++;
                }
            }
        )
        return c;
    }

    const getFunzione = (txt,icon) => {
        for(var i=0; i<props.funzioni.funzioni.length; i++){
            if(props.funzioni.funzioni[i].txt === txt){
                if(icon){
                    return props.funzioni.funzioni[i].icon
                }else{
                    return props.funzioni.funzioni[i].txt
                }
            }
        }
    }

    if(!props.primoAccesso){
        return(
            <PrimoAccesso txt={props.txtTour.primoAccesso} setLingua={props.setLingua}
                lingua={props.lingua} propsSelect={props.propsSelect}
                setPrimoAccesso={props.setPrimoAccesso}
                setDarkTheme={props.setDark}/>
        )
    }else{
        return(
            <>
                {!props.tour?<Tour txt={props.txtTour.tour} setTour={props.setTour} setDarkTheme={props.setDark}/>:null}
                <div className="home">
                    {props.nomi.map(
                        function(el, y){
                            counter=0;
                            if(getAppNumber(props.nomi[y].headerTitle,props.data))
                            {    
                                return <div className='tileCategoriaHome'>
                                    <span className={props.darkTheme?'titoloCategoriaHome altroHomeDark':'titoloCategoriaHome altroHomeLight'}
                                        onClick={()=>navigate("/categorie/"+props.nomi[y].id)}>{props.nomi[y].headerTitle}</span>
                                    <div className='slideApplicazioniHome'>
                                        {props.data.map(
                                            (el, i) => {
                                                if(counter<4){                                        
                                                    if(counter<3&&props.data[i].categoria===props.nomi[y].headerTitle){
                                                        counter++;
                                                        return <div key={i} onClick={()=>navigate("/applicazioni/"+props.data[i].id)}>
                                                            <RicettaHome dark={props.darkTheme} time={getFunzione(props.data[i].funzione,0)}
                                                                icon={getFunzione(props.data[i].funzione,1)}
                                                                immagine={props.data[i].img} titolo={props.data[i].title}/>
                                                        </div>
                                                    }
                                                    else if(counter===3){
                                                        counter++;
                                                        return <div key={i} className={props.darkTheme?'altroHome altroHomeDark':'altroHome altroHomeLight'}
                                                            onClick={()=>navigate("/categorie/"+props.nomi[y].id)}>
                                                            {props.altro}
                                                            <Freccia className={props.darkTheme?'frecciaAltroDark':'frecciaAltroLight'}/>
                                                        </div>
                                                    }                                        
                                                }
                                            }
                                        )}
                                    </div>
                                </div>
                            }
                        }
                    )}

                    
                    
                </div>
            </>
        );
    }
}

export default Home;
