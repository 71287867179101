import React,{ useState } from 'react';
import './popSelect.css'

const PopSelect = (props) => {

    const [currentField, setCurrentField] = useState(props.currentValue);

    return (
        <div className={props.darkTheme?'popSelect popSelectDark':'popSelect popSelectLight'}>
            <div className={props.darkTheme?'titlePopSelect titlePopSelectDark':'titlePopSelect titlePopSelectLight'}>{props.txt.title}</div>
            <div className='frameOpzioniPopSelect'>
                {props.txt.options.map(
                    (el,i)=>{
                        return <Tile darkTheme={props.darkTheme} index={i} currentField={currentField} setCurrentField={setCurrentField} txt={props.txt.options[i]} />
                    }
                )}
            </div>
            <div className={props.darkTheme?'btnPopSelect btnPopSelectDark':'btnPopSelect btnPopSelectLight'}>
                <div className='btnTextPopSelect'
                    onClick={()=>props.setPop(0)}>
                    {props.txt.btnAnnulla}
                </div>
                <div className='btnTextPopSelect'
                    onClick={()=>{
                        if(props.lingua){
                            props.setLingua(currentField)
                            props.setPop(0)
                        }else{
                            props.setUnita(currentField)
                            props.setPop(0)
                        }
                    }}>
                    {props.txt.btnSalva}
                </div>
            </div>
        </div>
    );
}

export default PopSelect;

const Tile = (props) => {
    return(
        <div className='tilePopSelect' onClick={()=>props.setCurrentField(props.index)}>
            <div className='frameRadioBtn'>
                <div className={props.darkTheme?'borderRadioBtnDark':'borderRadioBtnLight'}>
                    <div className='cerchioRadioBtn' style={props.darkTheme?
                        props.currentField===props.index?{"backgroundColor":"#FFE985"}:{"":""}
                        :props.currentField===props.index?{"backgroundColor":"#0486FF"}:{"":""}}/>
                </div>
            </div>
            <div className='txtTilePopSelect'>
                <div className='txtTilePopSelectTitle'>{props.txt.title}</div>
                <div className='txtTilePopSelectTxt'>{props.txt.txt}</div>
            </div>
        </div>
    );
}