const CheckFiltri = (filtriAttivi,categoria,settore,funzione) =>{
    if(filtriAttivi.length===0){
        return 1;
    }
    for(var i=0; i<filtriAttivi.length; i++){
        if(filtriAttivi[i].txt===categoria || filtriAttivi[i].txt===settore || filtriAttivi[i].txt===funzione)
        {
            return 1;
        }
    }
    
    return 0;
}

export default CheckFiltri;