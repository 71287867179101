import './share.css'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton, RedditShareButton, TelegramShareButton, TelegramIcon } from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon, LinkedinIcon, RedditIcon } from "react-share";
import { ReactComponent as Copia } from './../../risorse/icone/profilo/copia.svg'
import React from 'react';

const Share = (props) => {

    const returnUrl = () => {
        if(window.location.pathname==='/user'){
            return window.location.href.replace(window.location.pathname,'');
        }else{
            return window.location.href;
        }
        
    }

    return (
        <div className={props.darkTheme?'share shareDark':'share shareLight'}>
            <div className='shareTitle'>{props.txt.share}</div>
            <div className='colIconeShare'>
                
                <div className='slideIconeShare'>
                    <FacebookShareButton url={returnUrl()} className="tileShare" onClick={()=>props.setPopShare(0)}>
                        <FacebookIcon size={48} round />
                        Facebook
                    </FacebookShareButton>

                    <TwitterShareButton url={returnUrl()} className="tileShare" onClick={()=>props.setPopShare(0)}>
                        <TwitterIcon size={48} round />
                        Twitter
                    </TwitterShareButton>

                    <WhatsappShareButton url={returnUrl()} className="tileShare" onClick={()=>props.setPopShare(0)}>
                        <WhatsappIcon size={48} round />
                        Whatsapp
                    </WhatsappShareButton>
                </div>

                <div className='slideIconeShare'>
                    <LinkedinShareButton url={returnUrl()} className="tileShare" onClick={()=>props.setPopShare(0)}>
                        <LinkedinIcon size={48} round />
                        Linkedin
                    </LinkedinShareButton>

                    {/* <RedditShareButton url={returnUrl()} className="tileShare" onClick={()=>props.setPopShare(0)}>
                        <RedditIcon size={48} round />
                        Reddit
                    </RedditShareButton> */}

                    <TelegramShareButton url={returnUrl()} className="tileShare" onClick={()=>props.setPopShare(0)}>
                        <TelegramIcon size={48} round />
                        Telegram
                    </TelegramShareButton>

                    <div className="tileShare"
                        onClick={()=>{
                            navigator.clipboard.writeText(returnUrl())
                            props.setPopShare(0)
                        }}>
                        <Copia/>
                        {props.txt.copia}
                    </div>
                </div>
            </div>
            <BottoneAnnulla {...props}/>
        </div>
    );
}

export default Share;

/* share
copia
annulla
*/



const BottoneAnnulla = (props) =>{
    return(
        <div className={props.darkTheme?'bottoneAnnullaShareDark':'bottoneAnnullaShareLight'}
            onClick={()=>props.setPopShare(0)}>
            {props.txt.annulla}
        </div>
    );
}