import React from 'react'


import { ReactComponent as Pasticceria } from './../../risorse/icone/filtri/settore/pasticceria.svg'
import { ReactComponent as Ristorazione } from './../../risorse/icone/filtri/settore/ristorazione.svg'
import { ReactComponent as Gelateria } from './../../risorse/icone/filtri/settore/gelateria.svg'
import { ReactComponent as Panificazione } from './../../risorse/icone/filtri/settore/panificazione.svg'

import { ReactComponent as Raffreddamento } from './../../risorse/icone/filtri/funzioni/Chilling.svg'
import { ReactComponent as Surgelazione } from './../../risorse/icone/filtri/funzioni/Freezing.svg'
import { ReactComponent as NonStop } from './../../risorse/icone/filtri/funzioni/Non-stop.svg'
import { ReactComponent as ConservazioneFredda } from './../../risorse/icone/filtri/funzioni/Cold Holding.svg'
import { ReactComponent as ConservazioneCalda } from './../../risorse/icone/filtri/funzioni/Warm holding.svg'
import { ReactComponent as Scongelamento } from './../../risorse/icone/filtri/funzioni/Thawing.svg'
import { ReactComponent as ProntoVendita } from './../../risorse/icone/filtri/funzioni/Ready.svg'
import { ReactComponent as CotturaBT } from './../../risorse/icone/filtri/funzioni/Low Temperture cooking.svg'
import { ReactComponent as ScioglimentoCioccolato } from './../../risorse/icone/filtri/funzioni/Chocolate.svg'
import { ReactComponent as LievitazioneDiretta } from './../../risorse/icone/filtri/funzioni/Proofing.svg'
import { ReactComponent as LievitazioneProgrammata } from './../../risorse/icone/filtri/funzioni/Planned prrofing.svg'
import { ReactComponent as FermaLievitazione } from './../../risorse/icone/filtri/funzioni/Retarder-proofing.svg'
import { ReactComponent as Pastorizzazione } from './../../risorse/icone/filtri/funzioni/Pasteurization.svg'
import { ReactComponent as Essiccazione } from './../../risorse/icone/filtri/funzioni/Drying.svg'

const fW = {"fontWeight":"600"};


const propsFra = {
    home: {
        path: "/",
        currentPage: 0,
        headerTxt: [
          "Accueil",
          "Profil"
        ]
    },
    ricerca: {
        path: "/ricerca",
        headerPlaceholder: "Chercher",
        headerReset: "Réinitialiser",
        currentPage: 1,
    },
    user: {
        path: "/user",
        currentPage: 2,
        tiles: [
            {
                title: "Unité",
                options: [
                    {
                        title: "Système métrique",
                        txt: "kg, °C"
                    },
                    {
                        title: "Système impérial",
                        txt: "lb, °F"
                    },
                ],
                btnSalva: "ENREGISTRER",
                btnAnnulla: "ANNULER"
            },
            {
                title: "Langue",
                options: [
                    {
                        title: "Italien",
                        txt: "Italiano"
                    },
                    {
                        title: "Anglais",
                        txt: "English"
                    },
                    {
                        title: "Espagnol",
                        txt: "Español"
                    },
                    {
                        title: "Français",
                        txt: "Français"
                    },
                    {
                        title: "Allemand",
                        txt: "Deutsch"
                    },
                ],
                btnSalva: "ENREGISTRER",
                btnAnnulla: "ANNULER"
            },
            {
                title: "Mode sombre",                
            },
            {
                title: "Répéter la visite de l'application",                
            },
            {
                title: "Partager",
                share: "Partager avec",
                copia: "Copie",
                annulla: "Annuler",              
            },
            {
                title: "À propos d'Irinox",                
            }
        ]
    },
    filtri: {
        path: "/filtri",
        headerTitle: "Filtres",
        headerReset: "Réinitialiser",
        currentPage: 3,
        btn: "Voir recettes",
        titleSettori: "Secteurs",
        titleCategorie: "Catégories",
        titleFunzioni: "Fonctions",
        settori: [
          {
            txt: "Pâtisserie",
            icon: Pasticceria,
          },
          {
            txt: "Restauration",
            icon: Ristorazione,
          },
          {
            txt: "Glacier",
            icon: Gelateria,
          },
          {
            txt: "Panification",
            icon: Panificazione,
          },
        ],
        funzioni: [
          {
            txt: "Refroidissement",
            icon: Raffreddamento,
          },
          {
            txt: "Surgélation",
            icon: Surgelazione,
          },
          {
            txt: "Non-Stop",
            icon: NonStop,
          },
          {
            txt: "Conservation froide",
            icon: ConservazioneFredda,
          },
          {
            txt: "Conservation chaude",
            icon: ConservazioneCalda,
          },
          {
            txt: "Décongélation",
            icon: Scongelamento,
          },
          {
            txt: "Prêt à servir",
            icon: ProntoVendita,
          },
          {
            txt: "Cuisson basse température",
            icon: CotturaBT,
          },
          {
            txt: "Fonte de chocolat",
            icon: ScioglimentoCioccolato,
          },
          {
            txt: "Fermentation directe",
            icon: LievitazioneDiretta,
          },
          {
            txt: "Fermentation programmée",
            icon: LievitazioneProgrammata,
          },
          {
            txt: "Fermentation contrôlée",
            icon: FermaLievitazione,
          },
          {
            txt: "Pasteurisation",
            icon: Pastorizzazione,
          },
          {
            txt: "Séchage",
            icon: Essiccazione,
          }
        ],
        categorie: [
          {
            txt: "Viande",
            icon: "",
          },
          {
            txt: "Légumes",
            icon: "",
          },
          {
            txt: "Pâtes/Riz",
            icon: "",
          },
          {
            txt: "Salsas/Sauces/Soupes",
            icon: "",
          },
          {
            txt: "Gâteaux",
            icon: "",
          },
          {
            txt: "Pain",
            icon: "",
          },
          {
            txt: "Fruits",
            icon: "",
          },
          {
            txt: "Glaces/Sorbets",
            icon: "",
          },
        ]
    }, 
    categorie: {
        path: "/categorie/:catid",
        currentPage: 4,
        nomi: [
            {
                id: 0,
                headerTitle: "Viande",
            },   
            {
                id: 1,
                headerTitle: "Légumes",
            },
            {
                id: 2,
                headerTitle: "Pâtes/Riz",
            },
            {
                id: 3,
                headerTitle: "Salsas/Sauces/Soupes",
            },
            {
                id: 4,
                headerTitle: "Gâteaux",
            },
            {
                id: 5,
                headerTitle: "Pain",
            },
            {
                id: 6,
                headerTitle: "Fruits",
            },
            {
                id: 7,
                headerTitle: "Glaces/Sorbets",
            }, 
        ],
    },   
    applicazioni: {
        path: "/applicazioni/:appid",
        currentPage: 4,
        altro: "Autre", 
        data: [],
        subTitle: [
          "Valeurs",
          "Remarques"
        ],
        txtValori: [
          "POIDS INITIAL",
          "POURCENTAGE FINAL",
          "POIDS FINAL",
          "CONSOMMATION D'ÉNERGIE Refroidissement rapide",
          "CONSOMMATION D'ÉNERGIE Congélation rapide"
        ],
        fasi: [
          [
            {
              title: "REFROIDISSEMENT",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "REFROIDISSEMENT",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "REFROIDISSEMENT",
              intervallo: [<span style={fW}>+10</span>,"°C"],
            },
            {
              title: "REFROIDISSEMENT",
              intervallo: [<span style={fW}>+50</span>,"°F"],
            }
          ],
          [
            {
              title: "REFROIDISSEMENT",
              intervallo: [<span style={fW}>+12</span>,"°C"],
            },
            {
              title: "REFROIDISSEMENT",
              intervallo: [<span style={fW}>+53,6</span>,"°F"],
            }
          ],
          [
            {
              title: "REFROIDISSEMENT",
              intervallo: [<span style={fW}>+25</span>,"°C"],
            },
            {
              title: "REFROIDISSEMENT",
              intervallo: [<span style={fW}>+77</span>,"°F"],
            }
          ],
          [
            {
              title: "SURGÉLATION",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "SURGÉLATION",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "SURGÉLATION",
              intervallo: [<span style={fW}>-12</span>,"°C"],
            },
            {
              title: "SURGÉLATION",
              intervallo: [<span style={fW}>+10,4</span>,"°F"],
            }
          ],
          [
            {
              title: "NON-STOP",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "NON-STOP",            
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "NON-STOP",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "NON-STOP",            
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "CONSERVATION FROIDE",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "CONSERVATION FROIDE",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "CONSERVATION FROIDE",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "CONSERVATION FROIDE",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "CONSERVATION CHAUDE",
              intervallo: [<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "CONSERVATION CHAUDE",
              intervallo: [<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "DÉCONGÉLATION",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "DÉCONGÉLATION",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "PRÊT À SERVIR",
              intervallo: ["da ",<span style={fW}>-18</span>," a ",<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "PRÊT À SERVIR",
              intervallo: ["da ",<span style={fW}>-0,4</span>," a ",<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "PRÊT À SERVIR",
              intervallo: ["da ",<span style={fW}>+3</span>," a ",<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "PRÊT À SERVIR",
              intervallo: ["da ",<span style={fW}>+37,4</span>," a ",<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "CUISSON BASSE TEMPÉRATURE",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "CUISSON BASSE TEMPÉRATURE",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "CUISSON BASSE TEMPÉRATURE",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "CUISSON BASSE TEMPÉRATURE",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "CUISSON BASSE TEMPÉRATURE",
              intervallo: [<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "CUISSON BASSE TEMPÉRATURE",
              intervallo: [<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "FONTE DE CHOCOLAT",
              intervallo: [<span style={fW}>+45</span>,"°C"],
            },
            {
              title: "FONTE DE CHOCOLAT",
              intervallo: [<span style={fW}>+113</span>,"°F"],
            }
          ],
          [
            {
              title: "FERMENTATION DIRECTE",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "FERMENTATION DIRECTE",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "FERMENTATION DIRECTE",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "FERMENTATION DIRECTE",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "FERMENTATION DIRECTE",
              intervallo: [<span style={fW}>+30</span>,"°C"],
            },
            {
              title: "FERMENTATION DIRECTE",
              intervallo: [<span style={fW}>+86</span>,"°F"],
            }
          ],
          [
            {
              title: "FERMENTATION PROGRAMMÉE",
              intervallo: ["da ",<span style={fW}>-18</span>," a ",<span style={fW}>+10</span>,"°C"],
            },
            {
              title: "FERMENTATION PROGRAMMÉE",
              intervallo: ["da ",<span style={fW}>-0,4</span>," a ",<span style={fW}>+50</span>,"°F"],
            }
          ],
          [
            {
              title: "FERMENTATION PROGRAMMÉE",
              intervallo: ["da ",<span style={fW}>+3</span>," a ",<span style={fW}>+10</span>,"°C"],
            },
            {
              title: "FERMENTATION PROGRAMMÉE",
              intervallo: ["da ",<span style={fW}>+37,4</span>," a ",<span style={fW}>+50</span>,"°F"],
            }
          ],
          [
            {
              title: "FERMENTATION CONTRÔLÉE",
              intervallo: "",
            },
            {
              title: "FERMENTATION CONTRÔLÉE",
              intervallo: "",
            }
          ],
          [
            {
              title: "PASTEURISATION",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "PASTEURISATION",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "PASTEURISATION",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "PASTEURISATION",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "SÉCHAGE",
              intervallo: "",
            },
            {
              title: "SÉCHAGE",
              intervallo: "",
            }
          ],
        ]
    },
    tour: {
      primoAccesso: [
        {
          lang: "FR",
          title: "Heures et températures indicatives",
          txt: [
            "Bienvenue à",
            "Tirez le meilleur parti de votre MF Next avec le Guide temps et températures étudié par nos chefs!"
          ],
          btn: "Commençons!",

        },
        {
          title: "Guida tempi e temperature richiede il tuo consenso per utilizzare i tuoi dati personali per:",
          txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nec sollicitudin diam. Suspendisse eget orci sagittis, porta dui sed, vestibulum est. Cras mollis interdum nisl, vitae ullamcorper turpis aliquam et. Suspendisse vulputate, metus ut ultrices congue, augue augue sollicitudin arcu, consectetur hendrerit sapien ipsum ac orci. Nam viverra semper lectus ut pulvinar. Nulla a leo vel risus vestibulum mattis. Etiam ut enim massa. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam blandit accumsan nunc, vitae lobortis justo finibus eget. Pellentesque vestibulum molestie justo, et iaculis augue feugiat sed.",
          btn1: "Acconsento",
          btn2: "Gestisci le opzioni"
        }
      ],
      tour:{
        schermoBlu: [
          {
            txt: "Il s'agit d'un court tutoriel qui vous guidera dans la navigation de votre application Web.",
            btn: "Lancez le tutoriel!",
            salta: "SAUTER"
          },
          {
            txt: "Le tutoriel est terminé. Amuse-toi bien!",
            btn: "Allez à la page d'accueil!",
          }
        ],
        tour: [
          {
            txt: "C'est la barre de navigation. La barre de navigation est un composant fixe que vous trouverez dans presque toutes les pages de l'application Web.",
            btn: "Continuer",
            salta: "SAUTER"
          },
          {
            txt: "C'est le bouton d'accueil. Chaque fois que vous appuyez dessus, cela vous ramènera à la page d'accueil.",
            btn: "Continuer",
            salta: "SAUTER"
          },
          {
            txt: "C'est le bouton de recherche. Chaque fois que vous appuyez dessus, vous accédez à la page du livre de recettes et vous pouvez rechercher votre application.",
            btn: "Continuer",
            salta: "SAUTER"
          },
          {
            txt: "C'est le bouton de profil. Chaque fois que vous appuyez dessus, cela vous amènera à la page de profil.",
            btn: "Continuer",
            salta: "SAUTER"
          },
        ]
      }
    }     
}
export default propsFra;