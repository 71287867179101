import TileProfilo from '../tile profilo/tileProfilo';
import './user.css'

import { ReactComponent as Condividi } from './../../risorse/icone/profilo/condividi.svg'
import { ReactComponent as Help } from './../../risorse/icone/profilo/help.svg'
import { ReactComponent as Info } from './../../risorse/icone/profilo/info.svg'
import { ReactComponent as Lingue } from './../../risorse/icone/profilo/lingue.svg'
import { ReactComponent as Righello } from './../../risorse/icone/profilo/righello.svg'
import { ReactComponent as DisplayLight } from './../../risorse/icone/profilo/displayLight.svg'
import { ReactComponent as DisplayDark } from './../../risorse/icone/profilo/displayDark.svg'
import Share from '../popup/share';
import React,{ useEffect, useState } from 'react';
import PopSelect from '../popup/popSelect';

var Icone;
const User = (props) => {

    if(props.darkTheme){
        Icone=[Righello,Lingue,DisplayDark,Help,Condividi,Info];
    }else{        
        Icone=[Righello,Lingue,DisplayLight,Help,Condividi,Info];
    }

    const [popUnita, setPopUnita] = useState(0);
    const [popLingua, setPopLingua] = useState(0);
    const [popShare, setPopShare] = useState(0);

    const [windowWidth, setWindowWith] = useState(window.innerWidth)

    const handleResize = () => {
        setWindowWith(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
    }, []);

    return(
        <div className='user'>
            {props.tiles.map(
                (el, i) => {
                    if(windowWidth<1200)//per togliere il tour per ora
                    {
                        return <div>
                            <TileProfilo key={i} darkTheme={props.darkTheme} setDarkTheme={props.setDarkTheme} icona={Icone[i]} title={props.tiles[i].title}
                            setPopShare={setPopShare} setPopUnita={setPopUnita} setPopLingua={setPopLingua}
                            setTour={props.setTour}
                            unita={i===0?1:0}
                            lingua={i===1?1:0}
                            display={i===2?1:0}
                            help={i===3?1:0}
                            share={i===4?1:0}
                            info={i===5?1:0}/>
                        </div>
                    }else if(i!==3&&i!==4){
                        return <div>
                            <TileProfilo key={i} darkTheme={props.darkTheme} setDarkTheme={props.setDarkTheme} icona={Icone[i]} title={props.tiles[i].title}
                            setPopShare={setPopShare} setPopUnita={setPopUnita} setPopLingua={setPopLingua}
                            setTour={props.setTour}
                            unita={i===0?1:0}
                            lingua={i===1?1:0}
                            display={i===2?1:0}
                            help={i===3?1:0}
                            share={i===4?1:0}
                            info={i===5?1:0}/>
                        </div>
                    }
                }
            )}
            <div className={popShare?'rettangoloblurShow':'rettangoloblurHidden'} onClick={()=>setPopShare(0)}/>

            <div className={popShare?'sharePopUpShow sharePopUp':'sharePopUpHidden sharePopUp'}>
                <Share darkTheme={props.darkTheme} setPopShare={setPopShare} txt={props.tiles[4]}/>
            </div>

            <div className={popUnita?'selectPopUpShow selectPopUp':'selectPopUpHidden selectPopUp'}>
                <PopSelect currentValue={props.unita} darkTheme={props.darkTheme} setPop={setPopUnita} setUnita={props.setUnita} txt={props.tiles[0]} lingua={0}/>
            </div>

            <div className={popLingua?'selectPopUpShow selectPopUp':'selectPopUpHidden selectPopUp'}>
                <PopSelect currentValue={props.lingua} darkTheme={props.darkTheme} setPop={setPopLingua} setLingua={props.setLingua} txt={props.tiles[1]} lingua={1}/>
            </div>


        </div>
    );
}

export default User;