import { GoogleSpreadsheet } from 'google-spreadsheet';

const SPREADSHEET_ID = "1x94ZeA9sVlr3Z3HKZTe1vi6ILdcbfXZr50qeQU8y7b0"
const PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDQvpH960fOrD1j\nwp7SKQ9e22ywP42oPozr67vszowpmZL/Ur0y+oNJnZxzcVYfW3HgYMr7DI3b5NIu\nJP0X8wLLDloENBkjT95VssVr4CS4IgM68+b2EKOeaD1I9VdPT5A7CxVmHTCOTm5K\nWA19oKoKtQhzG492ahvZf294DV6xzsCQSLbzYjpm9guLFCfdSXaF3DBnrBPaJv4W\nYL4srT+ejYZqkRTSGe/2nVpkqFXcA7SsJNtPDyreYTgu3TloST4IoLYTPdqhXlt/\nHOcYy3RJUp/hrDo8ZUfUMKhMOaBcaFEew5ybqSna358S9ivHjlNR0sXuUlrtbVD0\nenVaWFbRAgMBAAECggEAE67wncCmlgCsTkCfEMKh6ignLMxlW2jAiYuCPi9wzIho\ncc53vDuejx2/T6xvekf5aja+VSNnKgW+4u6GdhBqYkLRbqWJ/Egu9yZDEuvBEqLU\naqXukKPbhdee9cb6q2Yde8/M/gLBHh4brMN2TDMO7bEJ4a7G3ZveUelAFdIu7HWe\nlnHmckP2PQsD5d7PR06a5EEIA/64U+3W6fdp4vGNbQS7ZV4XEd77F87um3E91dbc\ngamppUWYHmtZapSmZxkM8J6o283vm34r73PNRjENXJeRLQ65ETnnjR+SI9P2Qhtl\nQtXLHYeY+4FH+fJQTdZhBumQn0fkmmuOJPJ8VIkPhQKBgQDwOBW8WjixkUrZWU+c\nIhiL08O0D18BywFBH/2lgCNc+kGwbsJFO9ZP9ny3123B8fng26jx89tJxir7dbhN\nhsCrMk+E5m/7j2UyH5DLmoKODAl8DwpdoiVuAWdLdQoGAGRKanoAKP1gCtIM9FU4\n1q0kRDY/36MXSOH60TbZgO077QKBgQDedSf5e7kIX6L5vGvLrVwYQmUHc3oTk0xv\nqPBNPRiKNJswsPZyi+/NPvq+j4N0ZXU4KBebepKX19kehDhhvKqnqs5HFI7Yjxki\ngxyZ13s4DI0o+aDaG9cdivMRPjUOpg7CyakxRDa4EWQZbVClOF9nJvB7Swfg4aaf\neKsBibhR9QKBgC72B+nGiYbNLKDJAPM5BytuvreYua4UDa65nlCYeFFY6zaesIPw\nark/KqqWBvL/ZyY6SxDh52DIyOZX4SvmyAuvzjPhCMtZASOHYDstfZBfcgs6tYAQ\njtiulY7+1nLN6M1Te35EhSQdeWrm6TIvZbHNU/2wrqWCrgrmk555wecRAoGAKJ+8\nbRLq91x/ppuCE+/UoZl+WZ387RY5Hyr6tyAxGwwASyQF3WUwpYqav7H1j4v3nAm2\nRe5cVt5UB/6dTTvm7jeC8/gNHf8D2AmZb36qQdII9TKg7TOoVPzK5ps1lTc9+tRY\n0WZjl7mVTg9oasOgqHWz9M/+4FJU20pt8Bn2TwECgYEAxwfajSdwrdfODUo7YUx/\n/KkZ27bhb/mRZhxw6PpAKGlWPZ3s4l8ppLZYkzPWzQbVRe9JkEOuHbX3VOFIkFNy\nuBR5oXDa9vC3s0SAIn9uK2/QhEnT21k7MdRsGPJ3HlEBqbXyjRpeNxCkOBVKP/fZ\nFsn6npcE04iKgelqQU37d4Y=\n-----END PRIVATE KEY-----\n"
const CLIENT_EMAIL =  "provacookandchill@lead-generation-cook-and-chill.iam.gserviceaccount.com"

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

const addSpreadsheetData = async (NewRow,setDatiCaricati,setShowPopUpCaricamento) => {

    var newRowWithId=NewRow;

    console.log("La nuova riga: ",newRowWithId)
    

    try {            
        await doc.useServiceAccountAuth({
            client_email: CLIENT_EMAIL,
            private_key: PRIVATE_KEY,
        });

        await doc.loadInfo();

        var sheet;
         
        sheet = doc.sheetsByTitle["Sheet1"]

        const rows = await sheet.getRows();

        var i=0;
        while(rows[i]!==undefined){
            i++;
        }

        newRowWithId.id=i;

        await sheet.addRow(newRowWithId);
        
        alert("Dati caricati")
        setDatiCaricati(1)
        setShowPopUpCaricamento(0)

    }catch (e) {
        console.error('Error: ', e);
    }
}

export default addSpreadsheetData;