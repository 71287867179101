import './ricetta.css'
import React from 'react';


const RicettaHome = (props) => {
    return(
        <div className={props.dark?'ricettaHome darkRicettaTile':'ricettaHome lightRicettaTile'}>
            <div className='fotoRicettaHome fotoRicettaTile' style={{"backgroundImage":`url(${props.immagine})`}}>
                <div className={props.dark?'tempoTileRicetta tempoTileRicettaDark':'tempoTileRicetta tempoTileRicettaLight'}>
                    {props.time}
                    <props.icon/>
                </div>
            </div>
            <span className='titoloRicettaHome'>{props.titolo}</span>
        </div>
    );
}

export default RicettaHome; 