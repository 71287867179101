import firebase from 'firebase';
const firebaseConfig = {
    apiKey: "",
    authDomain: "",
    projectId: "guida-tempi-temperature",
    storageBucket: "gs://guida-tempi-temperature.appspot.com",
    messagingSenderId: "378194783833",
    appId: ""
};
firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();
export default storage;




/* {
    "type": "service_account",
    "project_id": "guida-tempi-temperature",
    "private_key_id": "2ea4d9062f7ff36dc60931b5125c489beef8efec",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDRKYUgGIqZSNYr\npQdiiFQZP2ZoWA7BOhv0mPHDN8n9P0I/h3cXcLrvWp+tiaKZbxAgbz1DukWYFWnv\nETKV+ZOs25+EKSfgKQDNbJKd9BSyupe/mHC5HiLonug98dE6wlzSeRi5nIoWKkNR\nonLu+7GL3BwSqMbJu8/mOgpke2q5xuRYOcAHTHUiNgyGkmUL7hBQYSwM1/0mB0j6\nxdEyLmreGjNJpD+WMJEkrtrz2WbQ6f/xpVgmXTQOlKoZZOB4kAFUJ37oTVVHiuhx\nL7ifVpPgYF5KKpZfqca2fVcPbQRwGorGrqbeuwJhv4bsjG8JymoqPTPNcf9FSHdF\nC5UDX55fAgMBAAECggEAUK+tKLv+33HfGLgRWLvRgWnZNgBeNMEXXqyPeujvFTAN\nfNdjpBm7KzFw7q9tF7Zl6LUw04ssZKZCFtSyCHPeOW0BpKiBtvHqWtR63M73Gxlr\nB6ClP2w2ODlwytCZj4rB5vmLBbMy+6jzDi9WGgXjMFpJRBNymAjJAv2525hWi/JQ\nSe4LLIGZMtygeyXlcreBNBs7EXf1JvK6R197zOm2O3xgeA9sCLf+eC4jTRV5f6wa\noto2ZYyM79kFX66oFgsJTq9f+AcOGAY4wogGYDlouVDXD3FnXy7jnlx+dtZs+Tly\nsFR6l+rTW0ST20JgqX3ZfkRRZiZEHhjruQR/aLcpVQKBgQD5BHp0lFAmweEsg8Os\n7IN143ZCzOr7mn6hVbGfdrU8PUO8b6ZF4M/Xn3J1T8m2aOzqY82jNkeMdT5RrF3m\ng9vp3s6NZ5mFz6XaM3VvlSdSWZze012SC0kDaqtpuQWqQsI/hbYv8Prf1Z8kKuoq\nXmCN/mTXFtkEc6JbdQJ9ixPEmwKBgQDXBvLMqpMPSB31V/feyFapMW2a++Yx8XFi\nECnd/x6vTVupcQresi7L8BbZnyHYGTuVbOeBgBEDFpdOcIpornQH9+zY0lfGLpg6\nGByhOXRxN68lXDcnfb66mwTtqcrTpZoSvjEa4nSwRQhcUWKObbXyYvFKZ+RHZzL3\nykLv5TnPjQKBgDT+Yf0LTx9gZUYu6GaVksh01scSivNuhoJZFUrlXV5rf03LyOvY\nEwNFOHuEtl2uaLTc9ndEZueaT9/bucQDzbowo25uXLqgXSBZkSVk7ZBV58931dLt\nueUTP1Vpfa4lNOeF0ibTtuAJuytcQqcvnIa7W8R2ZUrQPP4Poa8L+PH/AoGAEyKD\nKMNlIs8AG30giaq6S4QihNA1w+wAcwGNODwjtuXjakis9vRURcGvxt3qXRihvMJc\nN6H14Zh5DnnlVztvB+aAJStStEghM8pBgfdi7VBPPbvfDJycBG8XGS8q5XqvI0vZ\ngtL0H1uFSvHTG2mX72Hupvhn0BLW7kYJft+fLWECgYEAih5zpW77xBBrKaE2wiHY\nVXh7ZIbfa6viv32mygtx1mL/AkhbKNVxy/XsZEHW9j2XmmdBk/BIuxfDMOf1UVS6\nCV8RzUvGQCSdTj2ZnnMzah3FZ0u8jIF7+7QLMXnzLDr9LqyFdvXVvkDaYL7e40PE\nA8seHzW+u3o+V66Ov0zJwiY=\n-----END PRIVATE KEY-----\n",
    "client_email": "firebase-adminsdk-qp087@guida-tempi-temperature.iam.gserviceaccount.com",
    "client_id": "111316884441587672885",
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-qp087%40guida-tempi-temperature.iam.gserviceaccount.com"
  } */