import './ricetta.css'
import React from 'react';


const RicettaRicettario = (props) => {
    if (props.grande){
        return(
            <div className={props.dark?'ricettaRicettario1 darkRicettaTile':'ricettaRicettario1 lightRicettaTile'}>
    
                <div className='fotoRicettaRicettario1 fotoRicettaTile' style={{"backgroundImage":`url(${props.immagine})`}}>
                    <div className={props.dark?'tempoTileRicetta tempoTileRicettaDark':'tempoTileRicetta tempoTileRicettaLight'}>
                        {props.time}
                        <props.icon/>
                    </div>
                </div>
                <span className={'titoloRicettaRicettario1'}>{props.titolo}</span>
    
            </div>
        );
    }
    else{
        return(
            <div className={props.dark?'ricettaRicettario2 darkRicettaTile':'ricettaRicettario2 lightRicettaTile'}>
    
                <div className='fotoRicettaRicettario2 fotoRicettaTile' style={{"backgroundImage":`url(${props.immagine})`}}/>
                <div className='descRicettaRicettario2'>
                    <span className={'titoloRicettaRicettario2'}>{props.titolo}</span>
                    <div className='tempoTileRicetta'>
                        {props.time}
                        <props.icon/>
                    </div>
                </div>
            </div>
        );
    }
    
}

export default RicettaRicettario; 