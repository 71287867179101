import './tour.css'
import React, { useEffect, useState } from "react"

import { ReactComponent as FrecciaPiena } from './../../risorse/icone/tour/freccia_piena_grande.svg'
import { ReactComponent as FrecciaPienaPiccola } from './../../risorse/icone/tour/freccia_piena_piccola.svg'
import { ReactComponent as FrecciaSkip } from './../../risorse/icone/tour/freccia.svg'

import { ReactComponent as Home } from './../../risorse/icone/Home.svg'
import { ReactComponent as Search } from './../../risorse/icone/Search.svg'
import { ReactComponent as User } from './../../risorse/icone/User.svg'

const Tour = (props) => {

    const [currentTourPage, SetCurrentTourPage] = useState(0)

    useEffect(()=>{
        props.setDarkTheme(0);
    })

    switch (currentTourPage) {
        case 0:
            return <PaginaBlu navigateToTourPage={1} txt={props.txt.schermoBlu[0]} SetCurrentTourPage={SetCurrentTourPage} setTour={props.setTour}/>
        
        case 1:
            return <PaginaTutorial navigateToTourPage={2} txt={props.txt.tour[0]} SetCurrentTourPage={SetCurrentTourPage} setTour={props.setTour}/>
        
        case 2:
            return <PaginaTutorial navigateToTourPage={3} txt={props.txt.tour[1]} SetCurrentTourPage={SetCurrentTourPage} setTour={props.setTour}/>
        
        case 3:
            return <PaginaTutorial navigateToTourPage={4} txt={props.txt.tour[2]} SetCurrentTourPage={SetCurrentTourPage} setTour={props.setTour}/>
        
        case 4:
            return <PaginaTutorial navigateToTourPage={5} txt={props.txt.tour[3]} SetCurrentTourPage={SetCurrentTourPage} setTour={props.setTour}/>
    
        case 5:
            return <PaginaBlu navigateToTourPage={6} txt={props.txt.schermoBlu[1]} setTour={props.setTour}/>
    }
    
}

export default Tour;

const PaginaBlu = ({txt,setTour,navigateToTourPage,SetCurrentTourPage}) => {

    return (
        <div className='tour paginaBluTour'>

            <div style={{"visibility":"hidden"}}></div>
            <div className='txtPrimoAccesso'>
                {txt.txt}
            </div>

            <div className='iniziamoPrimoAccesso' onClick={()=>{
                    if(navigateToTourPage===6){
                        setTour(1)
                    }else{
                        SetCurrentTourPage(navigateToTourPage)
                    }
                }}>
                {txt.btn}
                <FrecciaPiena/>
            </div>

            {txt.salta?
                <div className='skipTour skipPaginaBlu' onClick={()=>setTour(1)}>
                    {txt.salta}
                    <FrecciaSkip/>
                </div>
            :null}
        </div>
    )
}

const PaginaTutorial = (props) => {

    document.body.style.overflow = 'hidden';

    const [flagScuro, setFlagScuro] = useState(0)
    const [flagCerchio, setFlagCerchio] = useState(0)
    const [flagText, setFlagText] = useState(0)
    const [flagBtn, setFlagBtn] = useState(0)
    const [flagNavbar, setFlagNavbar] = useState(0)

    useEffect(()=>{
        setTimeout(() => {
            setFlagScuro(1);
            setTimeout(() => {                
                setFlagCerchio(1);
                setTimeout(() => {
                    setFlagText(1);
                    setTimeout(() => {
                        setFlagBtn(1)
                    }, 1500);
                }, 1800);
            },  1800);
        }, 500);
    },[props.navigateToTourPage])

    var styleCerchio='';

    const styleFlexMidLeft = {"textAlign":"left","alignItems":"flex-start"/* ,"paddingRight":"55px" */};
    
    const styleFlexMidRight = {"textAlign":"right","alignItems":"flex-end"/* ,"paddingLeft":"55px" */};

    if(props.navigateToTourPage===3){
        if(flagCerchio){
            styleCerchio='cerchioTourBegin cerchioTourBeginLeft cerchioTourEndLeft'
        }else if(!flagCerchio){
            styleCerchio='cerchioTourBegin cerchioTourBeginLeft'
        }
    }else if(props.navigateToTourPage===5){
        if(flagCerchio){
            styleCerchio='cerchioTourBegin cerchioTourBeginRight cerchioTourEndRight'
        }else if(!flagCerchio){
            styleCerchio='cerchioTourBegin cerchioTourBeginRight'
        }
    }else{
        if(flagCerchio){
            styleCerchio='cerchioTourBegin cerchioTourBeginCenter cerchioTourEndCenter'
        }else if(!flagCerchio){
            styleCerchio='cerchioTourBegin cerchioTourBeginCenter'
        }
    }

    return(
        <div className={flagScuro?'tour paginaTutorial':!flagScuro?'tour':''}>
            <Navbar dark={0} navigateToTourPage={props.navigateToTourPage} setFlagNavbar={setFlagNavbar} flagNavbar={flagNavbar}/>
            <div className={styleCerchio} style={{"zIndex":"1011"}}/>
            
            <div className='flexMidPaginaTutorial'
                style={props.navigateToTourPage===3?styleFlexMidLeft:props.navigateToTourPage===5?styleFlexMidRight:null}>
                <div className={flagText?'txtPaginaTutorial txtPaginaTutorialEnd':!flagText?'txtPaginaTutorial':''}>
                    {props.txt.txt}
                </div>

                <div className={flagBtn?'continuaPaginaTour continuaPaginaTourEnd':!flagBtn?'continuaPaginaTour':''}
                    onClick={()=>{
                        setFlagScuro(0);
                        setFlagCerchio(0);
                        setFlagText(0);
                        setFlagBtn(0);

                        setTimeout(() => {
                            setFlagNavbar(0);
                            setTimeout(() => {
                                props.SetCurrentTourPage(props.navigateToTourPage)                            
                            }, 300);
                        }, 350);
                    }}>
                    {props.txt.btn}
                    <FrecciaPienaPiccola/>
                </div>

            </div>
            

            
            
            <div className={flagBtn?'skipTour skipPaginaTutorial skipPaginaTutorialEnd':!flagBtn?'skipTour skipPaginaTutorial':''}
                onClick={()=>props.setTour(1)}>
                {props.txt.salta}
                <FrecciaSkip/>
            </div>
        </div>
    )
}

const Navbar = ({dark,navigateToTourPage,setFlagNavbar,flagNavbar}) => {

    useEffect(()=>{
        setTimeout(() => {
            setFlagNavbar(1) 
        }, 2430);
    },[navigateToTourPage])
    
    return(
        <div className={dark?'navbar navbarTour navbarDark':'navbar navbarTour navbarLight'} style={navigateToTourPage>2&&flagNavbar?{"zIndex":"1012", "visibility":'hidden'}:{"zIndex":"1012"}}>
            <div className='iconaNavBar' style={{"cursor":"default"}}>
                <div className='iconaNavBarTour' style={navigateToTourPage===3?{"visibility":"visible"}:{"":""}}>
                    <Home className={dark?"iconaNavBarSvgDark":"iconaNavBarSvgLight"}/>
                </div>                
            </div>
            <div className='iconaNavBar' style={{"cursor":"default"}}>
                <div className='iconaNavBarTour' style={navigateToTourPage===4?{"visibility":"visible"}:{"":""}}>
                    <Search className={dark?"iconaNavBarSvgDark":"iconaNavBarSvgLight"}/>
                </div>                
            </div>
            <div className='iconaNavBar' style={{"cursor":"default"}}>
                <div className='iconaNavBarTour' style={navigateToTourPage===5?{"visibility":"visible"}:{"":""}}>
                    <User className={dark?"iconaUserDark":"iconaUserLight"}/>
                </div>                
            </div>
        </div>
    );
}