import './header.css';
import { ReactComponent as LogoBlu } from './../../risorse/icone/logo_blu.svg'
import { ReactComponent as LogoBianco } from './../../risorse/icone/logo_bianco.svg'
import { ReactComponent as Search } from './../../risorse/icone/Search.svg'
import { ReactComponent as Cross } from './../../risorse/icone/Cross.svg'
import { ReactComponent as Arrow } from './../../risorse/icone/Arrow.svg'
import React,{ useEffect, useState } from 'react';


import { ReactComponent as Condividi } from './../../risorse/icone/profilo/condividi.svg'

import { useNavigate } from 'react-router-dom';
import Share from '../popup/share';

const Header = (props) => {

    const navigate = useNavigate();
    const [popShare, setPopShare] = useState(0);

    const [windowWidth, setWindowWith] = useState(window.innerWidth)

    const handleResize = () => {
        setWindowWith(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
    }, []);

    useEffect(()=>{
        if(props.filtriAttivi.length===0&&props.clickRicercaNavbar){
            props.setInputFocus(1);
            props.setClickRicercaNavbar(0)
        }else{
            props.setInputFocus(0);
        }
    }, [props.page,props.filtriAttivi.length])

    const HandleReset = () => {
        props.setInputValue("");
    }

    const pressInvio = (event) => {
        if (event.keyCode === 13){
            /* hide keyboard */
            document.activeElement.blur()
        }
    }

    /* get current scroll position */

    const [scrollPosition, setScrollPosition] = useState(0)

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, false);
    }, []);
    
    
    /* get current scroll position */


    if(props.page===1 && props.inputFocus){ 
        if(windowWidth<1200){      
            return(
                <div  className={props.dark?'headerRicerca header  headerDark':'headerRicerca header  headerLight'}>
                    <div className='flexCrossInput'>                    
                        <div className={props.dark?'searchBoxHead crossBoxHeadDark':"searchBoxHead crossBoxHeadLight"}
                            onClick={()=>props.setInputFocus(0)}>
                            <Cross/>
                        </div>
                        <input type="text" placeholder={props.placeholder} autoFocus className={props.dark?'inputHeader inputDark':"inputHeader inputLight"}
                            onChange={(value)=>props.setInputValue(value.target.value)}
                            onKeyDown={(e)=>{
                                pressInvio(e)
                            }}
                            value={props.inputValue}/>
                    </div>
                    <span className={props.dark?'resetHeader resetHeaderDark':'resetHeader resetHeaderLight'}
                        onClick={()=>HandleReset()}>{props.reset}</span>
                </div>
            );
        }else{
            return(
                <div  className={props.dark?'headerRicerca header  headerDark':'headerRicerca header  headerLight'}>
                    {props.dark?<LogoBianco onClick={()=>props.setInputFocus(0)} style={{"cursor":"pointer"}}/>:<LogoBlu onClick={()=>props.setInputFocus(0)} style={{"cursor":"pointer"}}/>}
                    <div className='flexRowRicercaDesktop'>
                        <div className='flexCrossInput'>  
                            {/* <span className={props.dark?'resetHeader resetHeaderDark':'resetHeader resetHeaderLight'}
                                onClick={()=>HandleReset()}>{props.reset}</span> */}

                            <input type="text" placeholder={props.placeholder} autoFocus className={props.dark?'inputHeader inputDark':"inputHeader inputLight"}
                                onChange={(value)=>props.setInputValue(value.target.value)}
                                onKeyDown={(e)=>{
                                    pressInvio(e)
                                }}
                                value={props.inputValue}/>                  
                            <div className={props.dark?'searchBoxHead crossBoxHeadDark':"searchBoxHead crossBoxHeadLight"}
                                onClick={()=>props.setInputFocus(0)}>
                                <Cross/>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }  
    }
    
    if(props.page === 0 || props.page === 2 || (props.page === 1 && !props.inputFocus)){       
        if(windowWidth<1200){       
            return(
                <div className={props.dark?'header headerDark':'header headerLight'}>
                    {props.dark?<LogoBianco/>:<LogoBlu/>}
                    {props.page===1?<div className={props.dark?'searchBoxHead searchBoxHeadDark':"searchBoxHead searchBoxHeadLight"}
                        onClick={()=>props.setInputFocus(1)}>
                        <Search/>
                    </div>:null}
                </div>
            );
        }else{
            return(
                <div className={props.dark?'header headerDark':'header headerLight'}>
                    {props.dark?<LogoBianco onClick={()=>navigate("/")} style={{"cursor":"pointer"}}/>:<LogoBlu onClick={()=>navigate("/")} style={{"cursor":"pointer"}}/>}
                    <div className='flexRowNavbarDesktop'>
                        <div className={props.dark?'linkHeaderDesktop linkHeaderDesktopDark':'linkHeaderDesktop linkHeaderDesktopLight'}
                            onClick={()=>navigate("/")}>
                            {props.txtLink[0]}
                        </div>
                        <div className={props.dark?'linkHeaderDesktop linkHeaderDesktopDark':'linkHeaderDesktop linkHeaderDesktopLight'}
                            onClick={()=>navigate("/user")}>
                            {props.txtLink[1]}
                        </div>
                        {props.page===1?<div className={props.dark?'searchBoxHead searchBoxHeadDark':"searchBoxHead searchBoxHeadLight"}
                            onClick={()=>props.setInputFocus(1)}>
                            <Search/>
                        </div>:
                        <div className={props.dark?'searchBoxHead searchBoxHeadDark':"searchBoxHead searchBoxHeadLight"}
                            onClick={()=>{                                
                                props.setClickRicercaNavbar(1)
                                navigate("/ricerca")
                            }}>
                            <Search/>
                    </div>}
                    </div>
                </div>
            );
        }
    }

    var currentClass; 
    
    if(window.location.pathname.slice(0,14)==="/applicazioni/"){
        if((scrollPosition < (windowWidth * 9/16 - 16)) && windowWidth < 1200){
            if(props.dark){
                currentClass="header headerRicerca headerAppDark"
            }else{
                currentClass="header headerRicerca headerAppLight"
            }
        }else{
            if(props.dark){
                currentClass="header headerRicerca headerDark"
            }else{
                currentClass="header headerRicerca headerLight"
            }
        }
    }else{
        if(props.dark){
            currentClass="header headerRicerca headerDark"
        }
        else{
            currentClass="header headerRicerca headerLight"
        }
    }
    
    if(props.page === 3 || props.page === 4){             
        return(
            <div className={currentClass}>
                <div className='flexCrossInput'>                    
                    <div className={props.dark?'searchBoxHead crossBoxHeadDark':"searchBoxHead crossBoxHeadLight"}
                        onClick={()=> navigate(-1)}>
                        <Arrow/>
                    </div>
                    <span className={props.dark?'titoloSezioneDark':'titoloSezioneLight'}>{props.page === 3?props.title:
                        window.location.pathname.substring(0,10)==="/categorie"?props.title.nomi[window.location.pathname.substring(11)].headerTitle:props.title.data[window.location.pathname.substring(14)].title
                    }</span>
                </div>
                {props.page === 3?<span className={props.dark?'resetHeader resetHeaderDark':'resetHeader resetHeaderLight'}
                    onClick={()=>props.setFiltriAttivi([])}>{props.reset}</span>:
                    window.location.pathname.slice(0,14)==="/applicazioni/"&&windowWidth<1200?
                        <div onClick={()=>setPopShare(1)}
                            className={props.dark?'iconaCondividiApp iconaCondividiAppDark':'iconaCondividiApp iconaProfiloLightfill'}>
                            <Condividi/>
                        </div>:
                        null}
                        
                <div className={popShare?'rettangoloblurShow':'rettangoloblurHidden'} onClick={()=>setPopShare(0)}/>
                <div className={popShare?'sharePopUpShow sharePopUp':'sharePopUpHidden sharePopUp'}>
                    <Share darkTheme={props.dark} setPopShare={setPopShare} txt={props.tiles[4]}/>
                </div>
            </div>
        );
    }
}
export default Header;