import './chips.css'
import React from 'react';

import { ReactComponent as Cross } from './../../risorse/icone/filtri/settore/cross.svg'

const Chips = (props) => {

    var currentClass;
    
    if(props.darkTheme){
        if(props.selected){
            currentClass="chip chipDarkSelected";
        }
        else{
            currentClass="chip chipDarkUnselected";
        }
    }else{
        if(props.selected){
            currentClass="chip chipLightSelected";
        }
        else{
            currentClass="chip chipLightUnselected";
        }
    }    

    return(
        <div className={currentClass}
            onClick={props.onClick}>
            {props.icon?<props.icon/>:null}
            <div style={{"width":"max-content"}}>{props.txt}</div>
            {props.cross?<Cross/>:null}
        </div>
    );
}

export default Chips;

/* darkTheme={props.darkTheme} selected={1} txt={"Filtro1"} icon={Inbuto} cross={1} */