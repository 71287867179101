import './categoria.css'
import RicettaRicettario from '../ricetta/ricettario';
import { useNavigate } from 'react-router-dom';
import React from 'react';


const Categoria = (props) => {

    const transformTime = (time) =>{
        var h=Math.floor(time/60);
        var min=time%60;
        var result;
        if(!h){
            result=min+" min";
        }else if(!min){
            result=h+" h"
        }else{
            result=h+" h "+min+" min";
        }
        return result;
    }
    
    const navigate = useNavigate();

    const getFunzione = (txt,icon) => {
        for(var i=0; i<props.funzioni.funzioni.length; i++){
            if(props.funzioni.funzioni[i].txt === txt){
                if(icon){
                    return props.funzioni.funzioni[i].icon
                }else{
                    return props.funzioni.funzioni[i].txt
                }
            }
        }
    }

    return(
        <div className='categoria'>
            {props.data.map(
                (el, i) => {                                      
                    if(props.data[i].categoria===props.nomi[window.location.pathname.substring(11)].headerTitle){
                        return <div onClick={()=>navigate("/applicazioni/"+props.data[i].id)}>
                            <RicettaRicettario key={i} dark={props.darkTheme} time={getFunzione(props.data[i].funzione,0)}
                            icon={getFunzione(props.data[i].funzione,1)}
                            immagine={props.data[i].img} titolo={props.data[i].title} grande={1}/>
                        </div>
                    }  
                }
            )}
            
        </div>
    );
}

export default Categoria;


{/* <RicettaRicettario dark={darkTheme} time={"1h"} immagine={Immagine} titolo={"Titolo ricetta"} grande={0}/>  
<RicettaRicettario dark={darkTheme} time={"1h"} immagine={Immagine} titolo={"Titolo ricetta"} grande={1}/>
<RicettaRicettario dark={darkTheme} time={"1h"} immagine={Immagine} titolo={"Titolo ricetta"} grande={0}/>  
<RicettaRicettario dark={darkTheme} time={"1h"} immagine={Immagine} titolo={"Titolo ricetta"} grande={1}/> */}