import './navbar.css'
import { ReactComponent as Home } from './../../risorse/icone/Home.svg'
import { ReactComponent as Search } from './../../risorse/icone/Search.svg'
import { ReactComponent as User } from './../../risorse/icone/User.svg'

import { useNavigate } from 'react-router-dom';

import React, { useEffect, useState } from 'react';


const Navbar = (props) => {

    const navigate = useNavigate();

    const [windowWidth, setWindowWith] = useState(window.innerWidth)

    const handleResize = () => {
        setWindowWith(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
    }, []);


    if ((props.page===1 && !props.inputFocus || props.page===0 || props.page===2) && windowWidth<1200){        
        return(
            <div className={props.dark?'navbar navbarDark':'navbar navbarLight'}  style={!props.tour?{"visibility":"hidden"}:null}>
                <div className={props.page===0?
                        props.dark?'iconaNavBar currentPageDark':'iconaNavBar currentPageLight'
                    :'iconaNavBar'} onClick={()=>{
                        navigate("/")
                    }}>
                    <Home className={props.dark?"iconaNavBarSvgDark":"iconaNavBarSvgLight"}/>
                </div>
                <div className={props.page===1?
                        props.dark?'iconaNavBar currentPageDark':'iconaNavBar currentPageLight'
                    :'iconaNavBar'} onClick={()=>{
                        props.setClickRicercaNavbar(1)
                        navigate("/ricerca")
                    }}>
                    <Search className={props.dark?"iconaNavBarSvgDark":"iconaNavBarSvgLight"}/>
                </div>
                <div className={props.page===2?
                        props.dark?'iconaNavBar currentPageUserDark':'iconaNavBar currentPageUserLight'
                    :'iconaNavBar'} onClick={()=>{
                        navigate("/user")
                    }}>
                    <User className={props.dark?"iconaUserDark":"iconaUserLight"}/>
                </div>
            </div>
        );
    }
    else{
        return(null);
    }
}

export default Navbar;