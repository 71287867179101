import Header from "../header/header";
import Navbar from "../navbar/navbar";
import './wrapper.css'
import React from 'react';


const Wrapper = (props) =>{   
    return(
        <div className={props.currentPage<=2?"wrapper":window.location.pathname.slice(0,14)==="/applicazioni/"?"wrapper3":"wrapper2"}>
            <Header dark={props.darkTheme} page={props.currentPage} placeholder={props.headerPlaceholder}
                title={props.headerTitle} inputFocus={props.inputFocus} setInputFocus={props.setInputFocus}
                reset={props.headerReset} inputValue={props.inputValue} setInputValue={props.setInputValue}
                filtriAttivi={props.filtriAttivi} setFiltriAttivi={props.setFiltriAttivi}
                clickRicercaNavbar={props.clickRicercaNavbar} setClickRicercaNavbar={props.setClickRicercaNavbar}
                tiles={props.tile}
                txtLink={props.txtHeaderDesktop}/>      
            {props.children}      
            <Navbar primoAccesso={props.primoAccesso} tour={props.tour}
                dark={props.darkTheme} page={props.currentPage} setCurrentPage={props.setCurrentPage}
                inputFocus={props.inputFocus}
                clickRicercaNavbar={props.clickRicercaNavbar} setClickRicercaNavbar={props.setClickRicercaNavbar}/>
        </div>
    );
}
export default Wrapper;