import { useNavigate } from 'react-router-dom';
import './ricerca.css'
import RigaFiltri from '../riga filtri/rigaFiltri';
import React,{ useState } from 'react';
import RicettaRicettario from '../ricetta/ricettario';
import Chips from '../chips/chips';

import CheckFiltri from '../functions/checkFiltri';

const Ricerca = (props) => {
    

    const TransformToLowerCase = (txt) => {
        return txt.toLowerCase();
    }

    var currentValue = TransformToLowerCase(props.inputValue)

    const navigate = useNavigate();

    const transformTime = (time) =>{
        var h=Math.floor(time/60);
        var min=time%60;
        var result;
        if(!h){
            result=min+" min";
        }else if(!min){
            result=h+" h"
        }else{
            result=h+" h "+min+" min";
        }
        return result;
    }

    const SearchInput = (title, categoria, input) => {
        
        var found = [];

        var inputWords=input.split(' ');

        var contatoreSpazio = inputWords.length;

        inputWords.map((el,index)=>found[index]=false)

        for(var y=0; y<contatoreSpazio; y++){
            for(var i=0; i<=title.length; i++){
                if(title.substring(i, i+inputWords[y].length)===inputWords[y]){
                    found[y]=true;
                }
            }

            for(var i=0; i<=categoria.length; i++){
                if(categoria.substring(i, i+inputWords[y].length)===inputWords[y]){
                    found[y]=true;
                }
            }
        }

        if(found.every((currentValue) => currentValue === true)){
            return 1;
        }
        else{
            return 0;
        }       
    }

    const showApp = (title, categoria, input) => {

        title = TransformToLowerCase(title);
        categoria = TransformToLowerCase(categoria);
        
        var currentPortion=title.substring(0, input.length)
        var currentPortionCat=categoria.substring(0, input.length)

        var found = SearchInput(title, categoria, input);

        if(input.length){
            if (input === currentPortion || found || input === currentPortionCat){
                return 1;
            }else{
                return 0;
            }            
        }
        else{
            return 0;
        }
    }

    const [vistaCorrente, setVistaCorrente] = useState(0);

    const HandleClick = (i) => {
        var arr=[...props.filtriAttivi]
        arr.splice(i,1)
        props.setFiltriAttivi([...arr])
    }

    const getFunzione = (txt,icon) => {
        for(var i=0; i<props.filtri.funzioni.length; i++){
            if(props.filtri.funzioni[i].txt === txt){
                if(icon){
                    return props.filtri.funzioni[i].icon
                }else{
                    return props.filtri.funzioni[i].txt
                }
            }
        }
    }
    /* time={getFunzione(props.data[i].funzione,0)}
    icon={getFunzione(props.data[i].funzione,1)} */
    
    if(props.inputFocus){
        return(
            <div className='ricerca'>
                {props.data.map(
                    (el, i) => {                                  
                        if(showApp(props.data[i].title,props.data[i].categoria,currentValue)){
                            return <div key={i} onClick={()=>navigate("/applicazioni/"+props.data[i].id)}>
                                <RicettaRicettario dark={props.darkTheme} time={getFunzione(props.data[i].funzione,0)}
                                    icon={getFunzione(props.data[i].funzione,1)}
                                    immagine={props.data[i].img} titolo={props.data[i].title} grande={1}/>
                            </div>
                        }  
                    }
                )}
            </div>
        );
    }else{
        return(
            <div className='ricettario'>
                <RigaFiltri darkTheme={props.darkTheme} filtri={props.filtri} vistaCorrente={vistaCorrente} setVistaCorrente={setVistaCorrente}/>

                <div className='slideChipsRicettario'>
                    {props.filtriAttivi.map(
                        (el,i) => {
                            return <Chips key={i} onClick={()=>HandleClick(i)} darkTheme={props.darkTheme}
                                        cross={1} txt={el.txt} icon={el.icon}/>
                        }
                    )}
                </div>                
                
                <div className='slideAppRicettario'>
                    {props.data.map(
                        (el, i) => {
                            if(CheckFiltri(props.filtriAttivi,el.categoria,el.settore,el.funzione)){                                                     
                                return <div key={i} onClick={()=>navigate("/applicazioni/"+props.data[i].id)}>
                                    <RicettaRicettario dark={props.darkTheme} time={getFunzione(props.data[i].funzione,0)}
                                        icon={getFunzione(props.data[i].funzione,1)}
                                        immagine={props.data[i].img} titolo={props.data[i].title}
                                        grande={vistaCorrente?0:1}/>
                                </div>
                            }   
                        }
                    )}
                </div>
            </div>
        );
    }
    
}

export default Ricerca;