import React, { useEffect, useState } from "react";
import './nomeTempoFasi.css'

const NomeTempoFasiComponent = (props) => {

    const [numeroFasi, setNumeroFasi] = useState()

    const [reset, setReset] = useState(0);

    const [arrayNameTime, setArrayNameTime] = useState([]);

    var ArrayNumeroFasi=[];

    const [vNumeroFasi, setVNumeroFasi] = useState([])

    const [arrayChecked, setArrayChecked] = useState(0)

    const SelectFasi= [
        {
            txt: "Raffreddamento +3°",
            intervallo: [<span>+3</span>,"°C"],
        },
        {
            txt: "Raffreddamento +10°",
            intervallo: [<span>+10</span>,"°C"],
        },
        {
            txt: "Raffreddamento +12°",
            intervallo: [<span>+12</span>,"°C"],
        },
        {
            txt: "Raffreddamento +25°",
            intervallo: [<span>+25</span>,"°C"],
        },
        {
            txt: "Surgelazione -18°",
            intervallo: [<span>-18</span>,"°C"],
        },
        {
            txt: "Surgelazione -12°",
            intervallo: [<span>-12</span>,"°C"],
        },
        {
            txt: "Non-Stop -18°",
            intervallo: [<span>-18</span>,"°C"],
        },
        {
            txt: "Non-Stop +3°",
            intervallo: [<span>+3</span>,"°C"],
        },
        {
            txt: "Conservazione fredda -18°",
            intervallo: [<span>-18</span>,"°C"],
        },
        {
            txt: "Conservazione fredda +3°",
            intervallo: [<span>+3</span>,"°C"],
        },
        {
            txt: "Conservazione calda +65°",
            intervallo: [<span>+65</span>,"°C"],
        },
        {
            txt: "Scongelamento +3°",
            intervallo: [<span>+3</span>,"°C"],
        },
        {
            txt: "Pronto vendita da -18° a +65°",
            intervallo: ["da ",<span>-18</span>," a ",<span>+65</span>,"°C"],
        },
        {
            txt: "Pronto vendita da +3° a +65°",
            intervallo: ["da ",<span>+3</span>," a ",<span>+65</span>,"°C"],
        },
        {
            txt: "Cottura bassa temperatura -18°",
            intervallo: [<span>-18</span>,"°C"],
        },
        {
            txt: "Cottura bassa temperatura +3°",
            intervallo: [<span>+3</span>,"°C"],
        },
        {
            txt: "Cottura bassa temperatura +65°",
            intervallo: [<span>+65</span>,"°C"],
        },
        {
            txt: "Scioglimento cioccolato +45°",
            intervallo: [<span>+45</span>,"°C"],
        },
        {
            txt: "Lievitazione diretta -18°",
            intervallo: [<span>-18</span>,"°C"],
        },
        {
            txt: "Lievitazione diretta +3°",
            intervallo: [<span>+3</span>,"°C"],
        },
        {
            txt: "Lievitazione diretta +30°",
            intervallo: [<span>+30</span>,"°C"],
        },
        {
            txt: "Lievitazione programmata da -18° a +10°",
            intervallo: ["da ",<span>-18</span>," a ",<span>+10</span>,"°C"],
        },
        {
            txt: "Lievitazione programmata da +3° a +10°",
            intervallo: ["da ",<span>+3</span>," a ",<span>+10</span>,"°C"],
        },
        {
            txt: "Ferma lievitazione",
            intervallo: "",
        },
        {
            txt: "Pastorizzazione -18°",
            intervallo: [<span>-18</span>,"°C"],
        },
        {
            txt: "Pastorizzazione +3°",
            intervallo: [<span>+3</span>,"°C"],
        },
        {
            txt: "Essiccazione",
            intervallo: "",
        }
      ]

    /* for(var i=0; i<numeroFasi; i++){
        ArrayNumeroFasi[i]= [i,i];
    } */

    useEffect(()=>{
        for(var i=0; i<numeroFasi; i++){
            ArrayNumeroFasi[i]= ["",""];
        }
        setVNumeroFasi(ArrayNumeroFasi)
    },[numeroFasi])

    const checkArray = () =>{
        var ok = 1;
        vNumeroFasi.map((el)=>{
            if(el[0] === "" || el[1] === ""){
                ok=0;
            }
        })

        if(ok)
        {
            setArrayChecked(1);
        }else{
            setArrayChecked(0);
        }
    }

    useEffect(()=>{
        checkArray()
    },[props.nome_fasi,props.tempo_per_fase])
    
    return(
        <div className="formSpreadSheet">
            <InputNumberFasi
                desc="Numero cicli utilizzati"
                value={numeroFasi}
                placeholder="Inserisci il numero dei cicli utilizzati"
                setValue={setNumeroFasi}
                reset={reset}
                />
                    
            <span className="messaggioCompilazioneForm">Attenzione! Importante! <br/> Compilare nome e tempo dei cicli nell'ordine di visualizzazione!!</span>

            {vNumeroFasi.map((el,i)=>{
                return (
                    <>
                        <InputSelectNomiFasi
                            id={i}
                            ArrayNumeroFasi={ArrayNumeroFasi}
                            setVNumeroFasi={setVNumeroFasi}
                            vNumeroFasi={vNumeroFasi}

                            value={props.nome_fasi}
                            desc={["Nome ciclo ",i+1]}
                            setValue={props.setnome_fasi}
                            campi={SelectFasi}
                        />
                        <InputTimeTempoFasi
                            id={i}
                            ArrayNumeroFasi={ArrayNumeroFasi}
                            setVNumeroFasi={setVNumeroFasi}
                            vNumeroFasi={vNumeroFasi}

                            desc={["Tempo ciclo ",i+1]}
                            placeholder1="Inserisci le ore"
                            placeholder2="Inserisci i minuti"
                            value={props.tempo_per_fase}
                            setValue={props.settempo_per_fase}
                        />
                    </>)
            })}

            <div className="navButtonFormSpreadsheet">
                <button onClick={()=>props.setPrimeSelectForm(0)}>Indietro</button>

                {props.nome_fasi||props.tempo_per_fase || numeroFasi?
                    <button onClick={()=>{
                        setNumeroFasi('')
                        props.setnome_fasi('')
                        props.settempo_per_fase('')
                        setReset(reset+1)
                    }}>Reset campi correnti</button>:null}
                
                {/* props.nome_fasi && props.tempo_per_fase &&  */numeroFasi && arrayChecked?
                    <button onClick={()=>{props.setNomeTempoFasi(1)}}>Procedi</button>
                :null}
            </div> 
        </div>
    )
}

export default NomeTempoFasiComponent;

const InputNumberFasi = (props) => {
    const [click, setClick] = useState(0)
    const [currentValue, setCurrentValue] = useState(props.value)

    useEffect(()=>{
        setClick(0)
    },[currentValue,props.reset])

    return(
        <div className="inputTextForm">
            <div className="descriprionInputForm">
                {props.desc}
            </div>
            <input disabled={click?"true":null}
                type="number"
                value={currentValue}
                placeholder={props.placeholder}
                onChange={(event)=>{setCurrentValue(event.target.value)}}
            />
            {!click && currentValue?<button onClick={()=>{  
                setClick(1) 
                props.setValue(currentValue);
            }}>Salva</button>:null}
        </div>
    );
}

const InputSelectNomiFasi = (props) => {

    const [currentOption, setCurrentOption] = useState(props.value)

    var arrayTmp = props.vNumeroFasi;

    useEffect(()=>{
        arrayTmp[props.id][0]=currentOption
        props.setVNumeroFasi(arrayTmp)
        var arrayValues = [];
        for(var i = 0; i < arrayTmp.length; i++){
            arrayValues[i] = arrayTmp[i][0];
        }
        var commaString = TransformWithComma(arrayValues);
        props.setValue(commaString)
    },[currentOption])

    return(
        <div className="inputTextForm">
            <div className="descriprionInputForm">
                {props.desc}
            </div>
            <select value={arrayTmp[props.id][0]} onChange={event => {                    
                    setCurrentOption(event.currentTarget.value)
                }}>
                <option disabled="true" value="">Seleziona...</option>
                {
                    props.campi.map((el,i)=>{
                        return <option value={i}>{el.txt}</option>                        
                    })
                }
            </select>
        </div>
    )
}

const InputTimeTempoFasi = (props) => {

    const [h, setH] = useState('');
    const [min, setMin] = useState('');
    const [res, setRes] = useState();

    useEffect(()=>{
        if(h && min){
            setRes(Number(h*60) + Number(min));

        }else if(h&&!min){
            setRes(Number(h*60)); 

        }else if(!h&&min){
            setRes(Number(min));
        }else{
            props.setValue('')
        }
    },[h,min])


    
    var arrayTmp = props.vNumeroFasi;

    useEffect(()=>{
        if(res){
            setRes(res.toString());
            arrayTmp[props.id][1]=res
            props.setVNumeroFasi(arrayTmp)
            var arrayValues = [];
            for(var i = 0; i < arrayTmp.length; i++){
                arrayValues[i] = arrayTmp[i][1];
            }
            var commaString = TransformWithComma(arrayValues);
            props.setValue(commaString)
        }
    },[res])

    return(
        <div className="inputTextForm">
            <div className="descriprionInputForm">
                {props.desc}
            </div>

            <div className="flexInputTempoForm">
                <input
                    style={{"width":"40%"}}
                    type="number"
                    value={h}
                    placeholder={props.placeholder1}
                    onChange={(event)=>{setH(event.target.value)}}
                />h
                <input
                    style={{"width":"40%"}}
                    type="number"
                    value={min}
                    placeholder={props.placeholder2}
                    onChange={(event)=>{setMin(event.target.value)}}
                />min
            </div>
        </div>
    );
}

const TransformWithComma = (array) => {
    var string='';
    array.map((el)=>string=string + el +";")

    string = string.slice(0,-1)

    return string;
}