import React from 'react'


import { ReactComponent as Pasticceria } from './../../risorse/icone/filtri/settore/pasticceria.svg'
import { ReactComponent as Ristorazione } from './../../risorse/icone/filtri/settore/ristorazione.svg'
import { ReactComponent as Gelateria } from './../../risorse/icone/filtri/settore/gelateria.svg'
import { ReactComponent as Panificazione } from './../../risorse/icone/filtri/settore/panificazione.svg'

import { ReactComponent as Raffreddamento } from './../../risorse/icone/filtri/funzioni/Chilling.svg'
import { ReactComponent as Surgelazione } from './../../risorse/icone/filtri/funzioni/Freezing.svg'
import { ReactComponent as NonStop } from './../../risorse/icone/filtri/funzioni/Non-stop.svg'
import { ReactComponent as ConservazioneFredda } from './../../risorse/icone/filtri/funzioni/Cold Holding.svg'
import { ReactComponent as ConservazioneCalda } from './../../risorse/icone/filtri/funzioni/Warm holding.svg'
import { ReactComponent as Scongelamento } from './../../risorse/icone/filtri/funzioni/Thawing.svg'
import { ReactComponent as ProntoVendita } from './../../risorse/icone/filtri/funzioni/Ready.svg'
import { ReactComponent as CotturaBT } from './../../risorse/icone/filtri/funzioni/Low Temperture cooking.svg'
import { ReactComponent as ScioglimentoCioccolato } from './../../risorse/icone/filtri/funzioni/Chocolate.svg'
import { ReactComponent as LievitazioneDiretta } from './../../risorse/icone/filtri/funzioni/Proofing.svg'
import { ReactComponent as LievitazioneProgrammata } from './../../risorse/icone/filtri/funzioni/Planned prrofing.svg'
import { ReactComponent as FermaLievitazione } from './../../risorse/icone/filtri/funzioni/Retarder-proofing.svg'
import { ReactComponent as Pastorizzazione } from './../../risorse/icone/filtri/funzioni/Pasteurization.svg'
import { ReactComponent as Essiccazione } from './../../risorse/icone/filtri/funzioni/Drying.svg'

const fW = {"fontWeight":"600"};


const propsIta = {
    home: {
        path: "/",
        currentPage: 0,
        headerTxt: [
          "Home",
          "Profilo"
        ]
    },
    ricerca: {
        path: "/ricerca",
        headerPlaceholder: "Cerca",
        headerReset: "Reset",
        currentPage: 1,
    },
    user: {
        path: "/user",
        currentPage: 2,
        tiles: [
            {
                title: "Unità",
                options: [
                    {
                        title: "Sistema metrico",
                        txt: "kg, °C"
                    },
                    {
                        title: "Sistema imperiale",
                        txt: "lb, °F"
                    },
                ],
                btnSalva: "SALVA",
                btnAnnulla: "ANNULLA"
            },
            {
                title: "Lingua",
                options: [
                    {
                        title: "Italiano",
                        txt: "Italiano"
                    },
                    {
                        title: "Inglese",
                        txt: "English"
                    },
                    {
                        title: "Spagnolo",
                        txt: "Español"
                    },
                    {
                        title: "Francese",
                        txt: "Français"
                    },
                    {
                        title: "Tedesco",
                        txt: "Deutsch"
                    },
                ],
                btnSalva: "SALVA",
                btnAnnulla: "ANNULLA"
            },
            {
                title: "Display",                
            },
            {
                title: "Ripeti tour dell’app",                
            },
            {
                title: "Condividi",
                share: "Condividi con",
                copia: "Copia",
                annulla: "Annulla",              
            },
            {
                title: "Informazioni su Irinox",                
            }
        ]
    },
    filtri: {
        path: "/filtri",
        headerTitle: "Filtri",
        headerReset: "Reset",
        currentPage: 3,
        btn: "Vedi applicazioni",
        titleSettori: "Settori",
        titleCategorie: "Categorie",
        titleFunzioni: "Funzioni",
        settori: [
          {
            txt: "Pasticceria",
            icon: Pasticceria,
          },
          {
            txt: "Ristorazione",
            icon: Ristorazione,
          },
          {
            txt: "Gelateria",
            icon: Gelateria,
          },
          {
            txt: "Panificazione",
            icon: Panificazione,
          },
        ],
        funzioni: [
          {
            txt: "Raffreddamento",
            icon: Raffreddamento,
          },
          {
            txt: "Surgelazione",
            icon: Surgelazione,
          },
          {
            txt: "Non-Stop",
            icon: NonStop,
          },
          {
            txt: "Conservazione fredda",
            icon: ConservazioneFredda,
          },
          {
            txt: "Conservazione calda",
            icon: ConservazioneCalda,
          },
          {
            txt: "Scongelamento",
            icon: Scongelamento,
          },
          {
            txt: "Pronto vendita",
            icon: ProntoVendita,
          },
          {
            txt: "Cottura b. t.",
            icon: CotturaBT,
          },
          {
            txt: "Scioglimento cioccolato",
            icon: ScioglimentoCioccolato,
          },
          {
            txt: "Lievitazione diretta",
            icon: LievitazioneDiretta,
          },
          {
            txt: "Lievitazione programmata",
            icon: LievitazioneProgrammata,
          },
          {
            txt: "Ferma lievitazione",
            icon: FermaLievitazione,
          },
          {
            txt: "Pastorizzazione",
            icon: Pastorizzazione,
          },
          {
            txt: "Essiccazione",
            icon: Essiccazione,
          }
        ],
        categorie: [
          {
            txt: "Carni",
            icon: "",
          },
          {
            txt: "Verdure",
            icon: "",
          },
          {
            txt: "Pasta/Riso",
            icon: "",
          },
          {
            txt: "Salse/Zuppe",
            icon: "",
          },
          {
            txt: "Torte",
            icon: "",
          },
          {
            txt: "Pane",
            icon: "",
          },
          {
            txt: "Frutta",
            icon: "",
          },
          {
            txt: "Gelati/Sorbetti",
            icon: "",
          },
        ]
    }, 
    categorie: {
        path: "/categorie/:catid",
        currentPage: 4,
        nomi: [
            {
                id: 0,
                headerTitle: "Carni",
            },   
            {
                id: 1,
                headerTitle: "Verdure",
            },
            {
                id: 2,
                headerTitle: "Pasta/Riso",
            },
            {
                id: 3,
                headerTitle: "Salse/Zuppe",
            },
            {
                id: 4,
                headerTitle: "Torte",
            },
            {
                id: 5,
                headerTitle: "Pane",
            },
            {
                id: 6,
                headerTitle: "Frutta",
            },
            {
                id: 7,
                headerTitle: "Gelati/Sorbetti",
            }, 
        ],
    },   
    applicazioni: {
        path: "/applicazioni/:appid",
        currentPage: 4,
        altro: "Altro", 
        data: [],
        subTitle: [
          "Valori",
          "Note"
        ],
        txtValori: [
          "PESO INIZIALE",
          "PERCENTUALE FINALE",
          "PESO FINALE",
          "CONSUMO ENERGETICO Raffreddamento",
          "CONSUMO ENERGETICO Surgelazione"
        ],
        fasi: [
          [
            {
              title: "RAFFREDDAMENTO",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "RAFFREDDAMENTO",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "RAFFREDDAMENTO",
              intervallo: [<span style={fW}>+10</span>,"°C"],
            },
            {
              title: "RAFFREDDAMENTO",
              intervallo: [<span style={fW}>+50</span>,"°F"],
            }
          ],
          [
            {
              title: "RAFFREDDAMENTO",
              intervallo: [<span style={fW}>+12</span>,"°C"],
            },
            {
              title: "RAFFREDDAMENTO",
              intervallo: [<span style={fW}>+53,6</span>,"°F"],
            }
          ],
          [
            {
              title: "RAFFREDDAMENTO",
              intervallo: [<span style={fW}>+25</span>,"°C"],
            },
            {
              title: "RAFFREDDAMENTO",
              intervallo: [<span style={fW}>+77</span>,"°F"],
            }
          ],
          [
            {
              title: "SURGELAZIONE",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "SURGELAZIONE",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "SURGELAZIONE",
              intervallo: [<span style={fW}>-12</span>,"°C"],
            },
            {
              title: "SURGELAZIONE",
              intervallo: [<span style={fW}>+10,4</span>,"°F"],
            }
          ],
          [
            {
              title: "NON-STOP",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "NON-STOP",            
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "NON-STOP",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "NON-STOP",            
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "CONSERVAZIONE FREDDA",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "CONSERVAZIONE FREDDA",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "CONSERVAZIONE FREDDA",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "CONSERVAZIONE FREDDA",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "CONSERVAZIONE CALDA",
              intervallo: [<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "CONSERVAZIONE CALDA",
              intervallo: [<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "SCONGELAMENTO",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "SCONGELAMENTO",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "PRONTO VENDITA",
              intervallo: ["da ",<span style={fW}>-18</span>," a ",<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "PRONTO VENDITA",
              intervallo: ["da ",<span style={fW}>-0,4</span>," a ",<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "PRONTO VENDITA",
              intervallo: ["da ",<span style={fW}>+3</span>," a ",<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "PRONTO VENDITA",
              intervallo: ["da ",<span style={fW}>+37,4</span>," a ",<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "COTTURA B. T.",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "COTTURA B. T.",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "COTTURA B. T.",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "COTTURA B. T.",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "COTTURA B. T.",
              intervallo: [<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "COTTURA B. T.",
              intervallo: [<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "SCIOGLIMENTO CIOCCOLATO",
              intervallo: [<span style={fW}>+45</span>,"°C"],
            },
            {
              title: "SCIOGLIMENTO CIOCCOLATO",
              intervallo: [<span style={fW}>+113</span>,"°F"],
            }
          ],
          [
            {
              title: "LIEVITAZIONE DIRETTA",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "LIEVITAZIONE DIRETTA",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "LIEVITAZIONE DIRETTA",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "LIEVITAZIONE DIRETTA",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "LIEVITAZIONE DIRETTA",
              intervallo: [<span style={fW}>+30</span>,"°C"],
            },
            {
              title: "LIEVITAZIONE DIRETTA",
              intervallo: [<span style={fW}>+86</span>,"°F"],
            }
          ],
          [
            {
              title: "LIEVITAZIONE PROGRAMMATA",
              intervallo: ["da ",<span style={fW}>-18</span>," a ",<span style={fW}>+10</span>,"°C"],
            },
            {
              title: "LIEVITAZIONE PROGRAMMATA",
              intervallo: ["da ",<span style={fW}>-0,4</span>," a ",<span style={fW}>+50</span>,"°F"],
            }
          ],
          [
            {
              title: "LIEVITAZIONE PROGRAMMATA",
              intervallo: ["da ",<span style={fW}>+3</span>," a ",<span style={fW}>+10</span>,"°C"],
            },
            {
              title: "LIEVITAZIONE PROGRAMMATA",
              intervallo: ["da ",<span style={fW}>+37,4</span>," a ",<span style={fW}>+50</span>,"°F"],
            }
          ],
          [
            {
              title: "FERMA LIEVITAZIONE",
              intervallo: "",
            },
            {
              title: "FERMA LIEVITAZIONE",
              intervallo: "",
            }
          ],
          [
            {
              title: "PASTORIZZAZIONE",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "PASTORIZZAZIONE",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "PASTORIZZAZIONE",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "PASTORIZZAZIONE",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "ESSICCAZIONE",
              intervallo: "",
            },
            {
              title: "ESSICCAZIONE",
              intervallo: "",
            }
          ],
        ]
    },
    tour: {
      primoAccesso: [
        {
          lang: "IT",
          title: "Guida tempi e temperature",
          txt: [
            "Benvenuto in",
            "Ottieni il massimo dal tuo MF Next con la Guida tempi e temperature studiata dai nostri chef!"
          ],
          btn: "Iniziamo!",

        },
        {
          title: "Guida tempi e temperature richiede il tuo consenso per utilizzare i tuoi dati personali per:",
          txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nec sollicitudin diam. Suspendisse eget orci sagittis, porta dui sed, vestibulum est. Cras mollis interdum nisl, vitae ullamcorper turpis aliquam et. Suspendisse vulputate, metus ut ultrices congue, augue augue sollicitudin arcu, consectetur hendrerit sapien ipsum ac orci. Nam viverra semper lectus ut pulvinar. Nulla a leo vel risus vestibulum mattis. Etiam ut enim massa. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam blandit accumsan nunc, vitae lobortis justo finibus eget. Pellentesque vestibulum molestie justo, et iaculis augue feugiat sed.",
          btn1: "Acconsento",
          btn2: "Gestisci le opzioni"
        }
      ],
      tour:{
        schermoBlu: [
          {
            txt: "Questo è un breve tutorial che ti guiderà nella navigazione della tua web app.",
            btn: "Inizia il tutorial!",
            salta: "SALTA"
          },
          {
            txt: "Il tutorial è terminato. Buon divertimento!",
            btn: "Vai alla home!",
          }
        ],
        tour: [
          {
            txt: "Questa è la navbar. La navbar è un componente fisso che ritroverai in quasi tutte le schermate della web app.",
            btn: "Continua",
            salta: "SALTA"
          },
          {
            txt: "Questo è il tasto home. Ogni volta che lo premerai ti riporterà alla schermata iniziale.",
            btn: "Continua",
            salta: "SALTA"
          },
          {
            txt: "Questo è il tasto ricerca. Ogni volta che lo premerai ti porterà alla schermata ricettario e potrai ricercare la tua applicazione. ",
            btn: "Continua",
            salta: "SALTA"
          },
          {
            txt: "Questo è il tasto profilo. Ogni volta che lo premerai ti porterà alla schermata del profilo.",
            btn: "Continua",
            salta: "SALTA"
          },
        ]
      }
    }     
}
export default propsIta;