import React, { useEffect, useState } from "react";
import storage from './../functions/firebase';

import './formSpreadSheet.css'

import { ReactComponent as Loading } from './../../risorse/icone/loading.svg'
import addSpreadsheetData from "../functions/addSpreadsheetData";
import CheckPassword from "../functions/checkPassword";
import NomeTempoFasiComponent from "../sezioni form/nomeTempoFasi";

const FormSpreadSheet = (props) => {
    document.body.style = 'background: #FAFBFB;';

    const [image, setImage] = useState('');
    const [Url, setUrl] = useState('');
    const [immagineEsistente, setImmagineEsistente] = useState('');
    const [showUploadImage, setShowUploadImage] = useState(1);

    const [passwordCorretta, setPasswordCorretta] = useState(0)    //mettere a 0

    const [linkOttenuto, setLinkOttenuto] = useState(0);    //mettere a 0

    const [titoliInseriti, setTitoliInseriti] = useState(0);    //mettere a 0

    const [PrimeSelectForm, setPrimeSelectForm] = useState(0);    //mettere a 0

    const [NomeTempoFasi, setNomeTempoFasi] = useState(0);    //mettere a 0

    const [Peso_Consumi, setPeso_Consumi] = useState(0);    //mettere a 0

    const [NoteInserite, setNoteInserite] = useState(0);    //mettere a 0
  

    //dichiarazione variabili per gli elementi della riga
    //l'id me lo devo calcolare quando aggiungo una riga (id ultima riga + 1)
    const [titleIta,settitleIta] = useState('')

    const [titleEng,settitleEng] = useState('')

    const [titleSpa,settitleSpa] = useState('')

    const [titleFra,settitleFra] = useState('')

    const [titleTed,settitleTed] = useState('')
    
    const [time,settime] = useState()
    
    const [img,setimg] = useState('')
    
    const [categoria,setcategoria] = useState('')
    
    const [settore,setsettore] = useState('')
    
    const [funzione,setfunzione] = useState('')
    
    const [dispositivo,setdispositivo] = useState('')
    
    const [performance,setperformance] = useState('')
    
    const [nome_fasi,setnome_fasi] = useState('')
    
    const [tempo_per_fase,settempo_per_fase] = useState('')
    
    const [peso_iniziale,setpeso_iniziale] = useState()
    
    const [peso_finale,setpeso_finale] = useState()
    
    const [consumi,setconsumi] = useState('')
    
    const [note_ita,setnote_ita] = useState('')
    
    const [note_eng,setnote_eng] = useState('')
    
    const [note_spa,setnote_spa] = useState('')
    
    const [note_fra,setnote_fra] = useState('')
    
    const [note_ted,setnote_ted] = useState('')


    const [newRow, setNewRow] = useState({})

    const [datiCaricati, setDatiCaricati] = useState(0)
    const [showPopUpCaricamento, setShowPopUpCaricamento] = useState(0)


    
    //dichiarazione variabili per gli elementi della riga

    const [numeroFasi, setNumeroFasi] = useState()

    const [reset, setReset] = useState(0);

    const [arrayNameTime, setArrayNameTime] = useState([]);

    var vNumeroFasi=[];

    for(var i=0; i<numeroFasi; i++){
        vNumeroFasi[i]= i;
    }

    const NomiDispositivi = [
        {
            txt: "MF Next S"
        },
        {
            txt: "MF Next M"
        },
        {
            txt: "MF Next L"
        },
        {
            txt: "MF Next SL"
        },
        {
            txt: "MF Next ML"
        },
        {
            txt: "MF Next LL"
        }
    ]

    const NomiPerformance = [
        {
            txt: "Standard"
        },
        {
            txt: "Turbo"
        },
        {
            txt: "Eco Silent"
        },
        {
            txt: "Turbo Silent"
        }
    ]

    const NomeFasiConsumo = [
        {
            txt: "Raffreddamento"
        },
        {
            txt: "Surgelazione"
        }
    ]

    const [consumo1, setConsumo1] = useState()
    const [consumo2, setConsumo2] = useState()

    useEffect(()=>{
        setconsumi(consumo1+";"+consumo2)
    },[consumo1,consumo2])
    
    const uploadimage = () => {
        if (image == null)
            return;
        setImmagineEsistente('')

        // Getting Meta Data Of File
        storage.ref("images").child(image.name).getMetadata()
            .then(() => {
                setImmagineEsistente("Immagine con lo stesso nome già esistente. Cambiare il nome dell'immagine")
                return;
            })
            .catch(()=>{                
  
                setUrl("Ottenendo il link. Attendere...")
                setShowUploadImage(0)
                // Sending File to Firebase Storage
                storage.ref(`/images/${image.name}`).put(image)
                    .on("state_changed", alert("Immagine caricata"), alert, () => {

                        // Getting Download Link
                        storage.ref("images").child(image.name).getDownloadURL()
                            .then((url) => {
                                setLinkOttenuto(1);
                                setUrl(url);
                                setimg(url);
                            })
                    });
            })
    }
  
    const [showIniziaIlform,setShowIniziaIlform] = useState(1)

    const HideIniziaIlForm = () => {
        setTimeout(() => {
            setShowIniziaIlform(0)
        }, 3000);
    }

    const [goodSize, setGoodSize] = useState(0)

    const HandleInputImageUpload = (e) => {
        if(e.target.files[0]){
            if(e.target.files[0].type.substring(0, e.target.files[0].type.indexOf("/"))!=="image"){   //check se il file è un'immagine
                alert("Il file da inserire deve essere un'immagine")
            }else{
                if(e.target.files[0].size < 2000000){
                    setImage(e.target.files[0])
                    setGoodSize(1);
                }else{
                    alert("L'immagine selezionata è troppo grande")
                }
            }
        }
    }

    const SplitCommaFasi = (fasi) => {
        fasi=fasi.split(';');
        
        var stringa='';

        fasi.map(el=>{
            el = parseInt(el)
            stringa = stringa + props.selectNomeFasi[el][0].title + ";"
        })

        stringa = stringa.slice(0,-1)
        return stringa;
    }

    const getFunzioni = () => {

        const funzioniPas=[
            {
                txt: "Raffreddamento",
                id: 0
            },
            {
                txt: "Surgelazione",
                id: 1
            },
            {
                txt: "Non-Stop",
                id: 2
            },
            {
                txt: "Conservazione fredda",
                id: 3
            },
            {
                txt: "Scongelamento",
                id: 5
            },
            {
                txt: "Cottura b. t.",
                id: 7
            },
            {
                txt: "Scioglimento cioccolato",
                id: 8
            },
            {
                txt: "Lievitazione diretta",
                id: 9
            },
            {
                txt: "Lievitazione programmata",
                id: 10
            },
            {
                txt: "Ferma lievitazione",
                id: 11
            },
            {
                txt: "Pastorizzazione",
                id: 12
            },
            {
                txt: "Essiccazione",
                id: 13
            }
        ];
        const funzioniR=[
            {
                txt: "Raffreddamento",
                id: 0
            },
            {
                txt: "Surgelazione",
                id: 1
            },
            {
                txt: "Non-Stop",
                id: 2
            },
            {
                txt: "Conservazione fredda",
                id: 3
            },
            {
                txt: "Conservazione calda",
                id: 4
            },
            {
                txt: "Scongelamento",
                id: 5
            },
            {
                txt: "Pronto vendita",
                id: 6
            },
            {
                txt: "Cottura b. t.",
                id: 7
            },
            {
                txt: "Lievitazione diretta",
                id: 9
            },
            {
                txt: "Lievitazione programmata",
                id: 10
            },
            {
                txt: "Pastorizzazione",
                id: 12
            },
        ];
        const funzioniG=[
            {
                txt: "Raffreddamento",
                id: 0
            },
            {
                txt: "Surgelazione",
                id: 1
            },
            {
                txt: "Non-Stop",
                id: 2
            },
            {
                txt: "Conservazione fredda",
                id: 3
            },
            {
                txt: "Pronto vendita",
                id: 6
            },
            {
                txt: "Cottura b. t.",
                id: 7
            },
            {
                txt: "Scioglimento cioccolato",
                id: 8
            },
            {
                txt: "Pastorizzazione",
                id: 12
            },
            {
                txt: "Essiccazione",
                id: 13
            }
        ];
        const funzioniPan=[
            {
                txt: "Raffreddamento",
                id: 0
            },
            {
                txt: "Surgelazione",
                id: 1
            },
            {
                txt: "Non-Stop",
                id: 2
            },
            {
                txt: "Conservazione fredda",
                id: 3
            },
            {
                txt: "Scongelamento",
                id: 5
            },
            {
                txt: "Lievitazione diretta",
                id: 9
            },
            {
                txt: "Lievitazione programmata",
                id: 10
            },
            {
                txt: "Ferma lievitazione",
                id: 11
            },
        ];

        if(settore===''){
            return props.txtSelect.funzioni
        }else if(settore==='Pasticceria'){
            return funzioniPas
        }else if(settore==='Ristorazione'){
            return funzioniR
        }else if(settore==='Gelateria'){
            return funzioniG
        }else if(settore==='Panificazione'){
            return funzioniPan
        }
    }

    
    const SistemaDatiSpreadSeet = () => {
        var funzioniCorrenti = getFunzioni();
        var idFunzione;
        var idSettore;
        var idCategoria

        for(var i=0; i<funzioniCorrenti.length; i++){
            if(funzioniCorrenti[i].txt===funzione){
                idFunzione = funzioniCorrenti[i].id
            }
        }

        switch (settore) {
            case "Pasticceria":
                idSettore = 0;
                break;
        
            case "Ristorazione":
                idSettore = 1;
                break;
        
            case "Gelateria":
                idSettore = 2;
                break;
        
            case "Panificazione":
                idSettore = 3;
                break;
        }

        for(var i=0; i<props.txtSelect.categorie.length; i++){
            if(props.txtSelect.categorie[i].txt===categoria){
                idCategoria = i;
            }
        }

        var row = newRow;

        row.funzione = idFunzione;
        row.settore = idSettore;
        row.categoria = idCategoria;
        
        setNewRow(row);
    }

    if(!passwordCorretta){
        return(
            <div className="formSpreadSheet">
                <CheckPassword setPasswordCorretta={setPasswordCorretta}/>
            </div>
        )
    }else if(!linkOttenuto){
        return (
            <div className="formSpreadSheet">     
                {showUploadImage? 
                    <div className="flexCaricamentoImmagine">
                        <span className="descriprionInputForm">Carica l'immagine (dimensione massima 2 Mb e orizzontale)</span>
                        <div>
                            <input type="file" onChange={(e) => { HandleInputImageUpload(e) }} />
                            <button onClick={goodSize?uploadimage:null}>Carica immagine</button>
                        </div>
                    </div>:null
                }
                <p>{immagineEsistente}</p>
                <p className="urlFormSpreadsheet">{Url}</p>    
                {!showUploadImage?<Loading className='loadingSvg'/>:null}        
            </div>
        );
    }else{
        if(!titoliInseriti){            
            return(
                <div className="formSpreadSheet">
                    {HideIniziaIlForm()}
                    {showIniziaIlform? <div className="flexColMsgUrl">
                        <span>Link ottenuto! Ora puoi compilare i campi!</span>
                        <button onClick={()=>setShowIniziaIlform(0)}>Ok</button>
                    </div>:null}

                    <InputText
                        desc="Titolo italiano"
                        value={titleIta}
                        placeholder="Inserisci il titolo italiano"
                        setValue={settitleIta}
                        />

                    <InputText
                        desc="Titolo inglese"
                        value={titleEng}
                        placeholder="Inserisci il titolo inglese"
                        setValue={settitleEng}
                        />

                    <InputText
                        desc="Titolo spagnolo"
                        value={titleSpa}
                        placeholder="Inserisci il titolo spagnolo"
                        setValue={settitleSpa}
                        />

                    <InputText
                        desc="Titolo francese"
                        value={titleFra}
                        placeholder="Inserisci il titolo francese"
                        setValue={settitleFra}
                        />

                    <InputText
                        desc="Titolo tedesco"
                        value={titleTed}
                        placeholder="Inserisci il titolo tedesco"
                        setValue={settitleTed}
                        />

                    {titleIta!=='' && titleEng!=='' && titleSpa!=='' && titleFra!=='' && titleTed!=='' &&
                    titleIta!==' ' && titleEng!==' ' && titleSpa!==' ' && titleFra!==' ' && titleTed!==' '?
                    <button onClick={()=>setTitoliInseriti(1)}>Procedi</button>:null}
                </div>
            )
        }else if(!PrimeSelectForm){
            return(
                <div className="formSpreadSheet">

                    <InputSelect
                        value={categoria}
                        desc="Categoria"
                        setValue={setcategoria}
                        campi={props.txtSelect.categorie}/>

                    <InputSelect
                        value={settore}
                        desc="Settore"
                        setValue={setsettore}
                        campi={props.txtSelect.settori}/>

                    <InputSelect
                        value={funzione}
                        desc="Funzione (di riferimento)"
                        setValue={setfunzione}
                        campi={getFunzioni()}/>

                    <InputSelect
                        value={dispositivo}
                        desc="Dispositivo"
                        setValue={setdispositivo}
                        campi={NomiDispositivi}/>

                    <InputSelect
                        value={performance}
                        desc="Performance"
                        setValue={setperformance}
                        campi={NomiPerformance}/>

                    <div className="navButtonFormSpreadsheet">
                        <button onClick={()=>setTitoliInseriti(0)}>Indietro</button>
                        
                        {categoria!== "" && settore!== "" && funzione!== "" && dispositivo!== "" && performance!== ""?
                            <button onClick={()=>{setPrimeSelectForm(1)}}>Procedi</button>
                        :null}
                    </div>                    
                </div>
            )
        }else if(!NomeTempoFasi){
            return <NomeTempoFasiComponent nome_fasi={nome_fasi} setPrimeSelectForm={setPrimeSelectForm}
                    tempo_per_fase={tempo_per_fase} setnome_fasi={setnome_fasi} settempo_per_fase={settempo_per_fase}
                    setNomeTempoFasi={setNomeTempoFasi}/>
        }else if(!Peso_Consumi){
            return(
                <div className="formSpreadSheet">

                    <InputNumber
                        desc="Peso iniziale (g)"
                        value={peso_iniziale}
                        placeholder="Inserisci il peso iniziale"
                        setValue={setpeso_iniziale}
                        />

                    <InputNumber
                        desc="Peso finale (g)"
                        value={peso_finale}
                        placeholder="Inserisci il peso finale"
                        setValue={setpeso_finale}
                        />           

                    <InputNumber
                        desc={["Consumo nella fase di ",NomeFasiConsumo[0].txt," (kWh) - da +90 a +3"]}
                        value={consumo1}
                        placeholder={["Inserisci il consumo nella fase di "+NomeFasiConsumo[0].txt]}
                        setValue={setConsumo1}
                        />                    

                    <InputNumber
                        desc={["Consumo nella fase di ",NomeFasiConsumo[1].txt," (kWh) - da +3 a -18"]}
                        value={consumo2}
                        placeholder={["Inserisci il consumo nella fase di "+NomeFasiConsumo[1].txt]}
                        setValue={setConsumo2}
                        />

                    <div className="navButtonFormSpreadsheet">
                        <button onClick={()=>setNomeTempoFasi(0)}>Indietro</button>
                        
                        {peso_iniziale && peso_finale && consumo1 && consumo2?
                            <button onClick={()=>{setPeso_Consumi(1)}}>Procedi</button>
                        :null}
                    </div>
                </div>
            )
        }else if(!NoteInserite){
            return(
                <div className="formSpreadSheet">
                    (inserimento non obbligatorio)
                    <InputText
                        desc="Note italiano"
                        value={note_ita}
                        placeholder="Inserisci le note in italiano"
                        setValue={setnote_ita}
                        />

                    <InputText
                        desc="Note inglese"
                        value={note_eng}
                        placeholder="Inserisci le note in inglese"
                        setValue={setnote_eng}
                        />

                    <InputText
                        desc="Note spagnolo"
                        value={note_spa}
                        placeholder="Inserisci le note in spagnolo"
                        setValue={setnote_spa}
                        />

                    <InputText
                        desc="Note francese"
                        value={note_fra}
                        placeholder="Inserisci le note in francese"
                        setValue={setnote_fra}
                        />

                    <InputText
                        desc="Note tedesco"
                        value={note_ted}
                        placeholder="Inserisci le note in tedesco"
                        setValue={setnote_ted}
                        />

                    <div className="navButtonFormSpreadsheet">
                        <button onClick={()=>setPeso_Consumi(0)}>Indietro</button>
                        
                        <button onClick={()=>{
                            setNoteInserite(1)

                            var arrayTempi = tempo_per_fase.split(';');
                            arrayTempi = arrayTempi.map(Number);

                            var tempoTotale=0;

                            arrayTempi.map((el)=>tempoTotale+=el);

                            setNewRow({
                                id: "",
                                title_ita: titleIta,
                                title_eng: titleEng,
                                title_spa: titleSpa,
                                title_fra: titleFra,
                                title_ted: titleTed,
                                time: tempoTotale,
                                img: img,
                                categoria: categoria,
                                settore: settore,
                                funzione: funzione,
                                dispositivo: dispositivo,
                                performance: performance,
                                nome_fasi: nome_fasi,
                                tempo_per_fase: tempo_per_fase,
                                peso_iniziale: peso_iniziale,
                                peso_finale: peso_finale,
                                consumi: consumi,
                                note_ita: note_ita,
                                note_eng: note_eng,
                                note_spa: note_spa,
                                note_fra: note_fra,
                                note_ted: note_ted
                            })
                        }}>Procedi</button>
                    </div>
                </div>
            )
        }else{
            return(
                <div className="formSpreadSheet">
                    <div style={{"marginBottom":"30px","fontSize":"20px","textAlign":"center"}}><span className="TitoloRecap">Recap (i tempi sono trasformati in minuti)</span></div>
                    <div><span className="TitoloRecap">titleIta:</span> {newRow.title_ita}</div>
                    <div><span className="TitoloRecap">titleEng:</span> {newRow.title_eng}</div>
                    <div><span className="TitoloRecap">titleSpa:</span> {newRow.title_spa}</div>
                    <div><span className="TitoloRecap">titleFra:</span> {newRow.title_fra}</div>
                    <div><span className="TitoloRecap">titleTed:</span> {newRow.title_ted}</div>
                    <div><span className="TitoloRecap">time (in min totali):</span> {newRow.time}</div>
                    
                    <div className="divImmagineForm"><span className="TitoloRecap">img:</span> <img className="immagineForm" src={newRow.img}/></div>

                    <div><span className="TitoloRecap">categoria:</span> {newRow.categoria}</div>
                    <div><span className="TitoloRecap">settore:</span> {newRow.settore}</div>
                    <div><span className="TitoloRecap">funzione:</span> {newRow.funzione}</div>
                    <div><span className="TitoloRecap">dispositivo:</span> {newRow.dispositivo}</div>
                    <div><span className="TitoloRecap">performance:</span> {newRow.performance}</div>
                    <div><span className="TitoloRecap">nome_cicli:</span> {SplitCommaFasi(nome_fasi)}</div>
                    <div><span className="TitoloRecap">tempo_per_ciclo:</span> {newRow.tempo_per_fase}</div>
                    <div><span className="TitoloRecap">peso_iniziale:</span> {newRow.peso_iniziale}</div>
                    <div><span className="TitoloRecap">peso_finale:</span> {newRow.peso_finale}</div>
                    <div><span className="TitoloRecap">fasi_consumo:</span> {newRow.fasi_consumo}</div>
                    <div><span className="TitoloRecap">consumi:</span> {newRow.consumi}</div>
                    <div><span className="TitoloRecap">note_ita:</span> {newRow.note_ita}</div>
                    <div><span className="TitoloRecap">note_eng:</span> {newRow.note_eng}</div>
                    <div><span className="TitoloRecap">note_spa:</span> {newRow.note_spa}</div>
                    <div><span className="TitoloRecap">note_fra:</span> {newRow.note_fra}</div>
                    <div><span className="TitoloRecap">note_ted:</span> {newRow.note_ted}</div>

                    <div className="navButtonFormSpreadsheet">
                        <button onClick={()=>setNoteInserite(0)}>Indietro</button>
                        
                        {!datiCaricati?
                            <button onClick={()=>{
                                setShowPopUpCaricamento(1);
                                SistemaDatiSpreadSeet();
                                addSpreadsheetData(newRow,setDatiCaricati,setShowPopUpCaricamento)
                                
                            }}>Aggiungi a excel</button>
                        :null}

                        {showPopUpCaricamento?<div className="popUpCaricamentoForm">
                            <Loading className='loadingSvg'/>
                            Caricamento...
                        </div>:null}

                        
                        {datiCaricati?<button onClick={()=>window.location.reload()}>Compila di nuovo il form</button>:null}
                    </div>
                </div>
            )
        }
    }
}

export default FormSpreadSheet;

const InputText = (props) => {
    return(
        <div className="inputTextForm">
            <div className="descriprionInputForm">
                {props.desc}
            </div>
            <input
                type="text"
                value={props.value}
                placeholder={props.placeholder}
                onChange={(event)=>{props.setValue(event.target.value)}}
            />
        </div>
    );
}

const InputSelect = (props) => {

    const [currentOption, setCurrentOption] = useState(props.value)

    return(
        <div className="inputTextForm">
            <div className="descriprionInputForm">
                {props.desc}
            </div>
            <select value={currentOption} onChange={event => {
                    props.setValue(event.currentTarget.value)
                    setCurrentOption(event.currentTarget.value)
                }}>
                <option disabled="true" value="">Seleziona...</option>
                {
                    props.campi.map((el)=>{
                        return <option value={el.txt}>{el.txt}</option>                        
                    })
                }
            </select>
        </div>
    )
}

const InputNumber = (props) => {

    useEffect(()=>{
        if(props.value){
            var currentValue=props.value/* .toString() */
            if(currentValue.indexOf(',')){
                currentValue=currentValue.replace(',','.')
                props.setValue(currentValue)
            }
        }
        
    },[props.value])

    return(
        <div className="inputTextForm">
            <div className="descriprionInputForm">
                {props.desc}
            </div>
            <input
                type="number"
                value={props.value}
                placeholder={props.placeholder}
                onChange={(event)=>{props.setValue(event.target.value)}}
            />
        </div>
    );
}