import './rigaFiltri.css'
import { ReactComponent as Inbuto } from './../../risorse/icone/inbuto.svg'
import { useNavigate } from 'react-router-dom';
import React from 'react';


const RigaFiltri = (props) => {

    const navigate = useNavigate();

    const getSyle = (id) =>{
        if(id===0){
            return props.darkTheme?
            props.vistaCorrente===0? {"outline":"solid 1px #71A3D2"}:{"":""}
            :props.vistaCorrente===0? {"outline":"solid 1px #33506A"}:{"":""}
        }else{
            return props.darkTheme?
            props.vistaCorrente===1? {"outline":"solid 1px #71A3D2"}:{"":""}
            :props.vistaCorrente===1? {"outline":"solid 1px #33506A"}:{"":""}
        }
    }

    return(
        <div className='rigaFiltri'>
            <div className='visteApplicazioniRigaFiltri'>
                <div className='frameVistaRigaFiltri' onClick={()=>props.setVistaCorrente(0)}>
                    <div className={props.darkTheme?'quadratoVistaRigaFiltri vistaApplicazioni1Dark':'quadratoVistaRigaFiltri vistaApplicazioni1Light'}
                        style={getSyle(0)}/>
                </div>
                <div className='frameVistaRigaFiltri' onClick={()=>props.setVistaCorrente(1)}>
                    <div className='quadratoVistaRigaFiltri' style={getSyle(1)}>
                        <div className={props.darkTheme?'righettaVista2Filtri righettaVista2FiltriDark':'righettaVista2Filtri righettaVista2FiltriLight'}/>
                        <div className={props.darkTheme?'righettaVista2Filtri righettaVista2FiltriDark':'righettaVista2Filtri righettaVista2FiltriLight'}/>
                        <div className={props.darkTheme?'righettaVista2Filtri righettaVista2FiltriDark':'righettaVista2Filtri righettaVista2FiltriLight'}/>
                    </div>
                </div>
            </div>
            <div className={props.darkTheme?'rigaFiltriTxt rigaFiltriTxtDark':'rigaFiltriTxt rigaFiltriTxtLight'}
                onClick={()=>navigate("/filtri")}>
                {props.filtri.headerTitle}
                <Inbuto/>
            </div>
        </div>
    );
}

export default RigaFiltri;