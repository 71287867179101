import React from 'react'


import { ReactComponent as Pasticceria } from './../../risorse/icone/filtri/settore/pasticceria.svg'
import { ReactComponent as Ristorazione } from './../../risorse/icone/filtri/settore/ristorazione.svg'
import { ReactComponent as Gelateria } from './../../risorse/icone/filtri/settore/gelateria.svg'
import { ReactComponent as Panificazione } from './../../risorse/icone/filtri/settore/panificazione.svg'

import { ReactComponent as Raffreddamento } from './../../risorse/icone/filtri/funzioni/Chilling.svg'
import { ReactComponent as Surgelazione } from './../../risorse/icone/filtri/funzioni/Freezing.svg'
import { ReactComponent as NonStop } from './../../risorse/icone/filtri/funzioni/Non-stop.svg'
import { ReactComponent as ConservazioneFredda } from './../../risorse/icone/filtri/funzioni/Cold Holding.svg'
import { ReactComponent as ConservazioneCalda } from './../../risorse/icone/filtri/funzioni/Warm holding.svg'
import { ReactComponent as Scongelamento } from './../../risorse/icone/filtri/funzioni/Thawing.svg'
import { ReactComponent as ProntoVendita } from './../../risorse/icone/filtri/funzioni/Ready.svg'
import { ReactComponent as CotturaBT } from './../../risorse/icone/filtri/funzioni/Low Temperture cooking.svg'
import { ReactComponent as ScioglimentoCioccolato } from './../../risorse/icone/filtri/funzioni/Chocolate.svg'
import { ReactComponent as LievitazioneDiretta } from './../../risorse/icone/filtri/funzioni/Proofing.svg'
import { ReactComponent as LievitazioneProgrammata } from './../../risorse/icone/filtri/funzioni/Planned prrofing.svg'
import { ReactComponent as FermaLievitazione } from './../../risorse/icone/filtri/funzioni/Retarder-proofing.svg'
import { ReactComponent as Pastorizzazione } from './../../risorse/icone/filtri/funzioni/Pasteurization.svg'
import { ReactComponent as Essiccazione } from './../../risorse/icone/filtri/funzioni/Drying.svg'

const fW = {"fontWeight":"600"};


const propsEng = {
    home: {
        path: "/",
        currentPage: 0,
        headerTxt: [
          "Home",
          "Profile"
        ]
    },
    ricerca: {
        path: "/ricerca",
        headerPlaceholder: "Search",
        headerReset: "Reset",
        currentPage: 1,
    },
    user: {
        path: "/user",
        currentPage: 2,
        tiles: [
            {
                title: "Unit",
                options: [
                    {
                        title: "Metric system",
                        txt: "kg, °C"
                    },
                    {
                        title: "Imperial system",
                        txt: "lb, °F"
                    },
                ],
                btnSalva: "SAVE",
                btnAnnulla: "CANCEL"
            },
            {
                title: "Language",
                options: [
                    {
                        title: "Italian",
                        txt: "Italiano"
                    },
                    {
                        title: "English",
                        txt: "English"
                    },
                    {
                        title: "Spanish",
                        txt: "Español"
                    },
                    {
                        title: "French",
                        txt: "Français"
                    },
                    {
                        title: "German",
                        txt: "Deutsch"
                    },
                ],
                btnSalva: "SAVE",
                btnAnnulla: "CANCEL"
            },
            {
                title: "Display",                
            },
            {
                title: "Repeat app tour",                
            },
            {
                title: "Share",
                share: "Share with",
                copia: "Copy",
                annulla: "Cancel",              
            },
            {
                title: "About Irinox",                
            }
        ]
    },
    filtri: {
        path: "/filtri",
        headerTitle: "Filters",
        headerReset: "Reset",
        currentPage: 3,
        btn: "Show applications",
        titleSettori: "Sectors",
        titleCategorie: "Categories",
        titleFunzioni: "Functions",
        settori: [
          {
            txt: "Pastry",
            icon: Pasticceria,
          },
          {
            txt: "Gastronomy",
            icon: Ristorazione,
          },
          {
            txt: "Ice-cream",
            icon: Gelateria,
          },
          {
            txt: "Bread making",
            icon: Panificazione,
          },
        ],
        funzioni: [
          {
            txt: "Chilling",
            icon: Raffreddamento,
          },
          {
            txt: "Freezing",
            icon: Surgelazione,
          },
          {
            txt: "Non-Stop",
            icon: NonStop,
          },
          {
            txt: "Cold holding",
            icon: ConservazioneFredda,
          },
          {
            txt: "Warm holding",
            icon: ConservazioneCalda,
          },
          {
            txt: "Thawing",
            icon: Scongelamento,
          },
          {
            txt: "Ready to serve",
            icon: ProntoVendita,
          },
          {
            txt: "Low-temperature cooking",
            icon: CotturaBT,
          },
          {
            txt: "Melting chocolate",
            icon: ScioglimentoCioccolato,
          },
          {
            txt: "Direct proofing",
            icon: LievitazioneDiretta,
          },
          {
            txt: "Programmed proofing",
            icon: LievitazioneProgrammata,
          },
          {
            txt: "Retarded proofing",
            icon: FermaLievitazione,
          },
          {
            txt: "Pasteurization",
            icon: Pastorizzazione,
          },
          {
            txt: "Drying",
            icon: Essiccazione,
          }
        ],
        categorie: [
          {
            txt: "Meat",
            icon: "",
          },
          {
            txt: "Vegetables",
            icon: "",
          },
          {
            txt: "Pasta/Rice",
            icon: "",
          },
          {
            txt: "Salsas/Sauces/Soups",
            icon: "",
          },
          {
            txt: "Cakes",
            icon: "",
          },
          {
            txt: "Bread",
            icon: "",
          },
          {
            txt: "Fruits",
            icon: "",
          },
          {
            txt: "Ice-creams/Sorbets",
            icon: "",
          },
        ]
    }, 
    categorie: {
        path: "/categorie/:catid",
        currentPage: 4,
        nomi: [
            {
                id: 0,
                headerTitle: "Meat",
            },   
            {
                id: 1,
                headerTitle: "Vegetables",
            },
            {
                id: 2,
                headerTitle: "Pasta/Rice",
            },
            {
                id: 3,
                headerTitle: "Salsas/Sauces/Soups",
            },
            {
                id: 4,
                headerTitle: "Cakes",
            },
            {
                id: 5,
                headerTitle: "Bread",
            },
            {
                id: 6,
                headerTitle: "Fruits",
            },
            {
                id: 7,
                headerTitle: "Ice-creams/Sorbets",
            }, 
        ],
    },   
    applicazioni: {
        path: "/applicazioni/:appid",
        currentPage: 4,
        altro: "Other", 
        data: [],
        subTitle: [
          "Values",
          "Notes"
        ],
        txtValori: [
          "INITIAL WEIGHT",
          "FINAL PERCENTAGE",
          "FINAL WEIGHT",
          "ENERGY CONSUMPTION Blast chilling",
          "ENERGY CONSUMPTION Shock freezing"
        ],
        fasi: [
          [
            {
              title: "CHILLING",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "CHILLING",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "CHILLING",
              intervallo: [<span style={fW}>+10</span>,"°C"],
            },
            {
              title: "CHILLING",
              intervallo: [<span style={fW}>+50</span>,"°F"],
            }
          ],
          [
            {
              title: "CHILLING",
              intervallo: [<span style={fW}>+12</span>,"°C"],
            },
            {
              title: "CHILLING",
              intervallo: [<span style={fW}>+53,6</span>,"°F"],
            }
          ],
          [
            {
              title: "CHILLING",
              intervallo: [<span style={fW}>+25</span>,"°C"],
            },
            {
              title: "CHILLING",
              intervallo: [<span style={fW}>+77</span>,"°F"],
            }
          ],
          [
            {
              title: "FREEZING",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "FREEZING",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "FREEZING",
              intervallo: [<span style={fW}>-12</span>,"°C"],
            },
            {
              title: "FREEZING",
              intervallo: [<span style={fW}>+10,4</span>,"°F"],
            }
          ],
          [
            {
              title: "NON-STOP",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "NON-STOP",            
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "NON-STOP",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "NON-STOP",            
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "COLD HOLDING",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "COLD HOLDING",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "COLD HOLDING",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "COLD HOLDING",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "WARM HOLDING",
              intervallo: [<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "WARM HOLDING",
              intervallo: [<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "THAWING",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "THAWING",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "READY TO SERVE",
              intervallo: ["da ",<span style={fW}>-18</span>," a ",<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "READY TO SERVE",
              intervallo: ["da ",<span style={fW}>-0,4</span>," a ",<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "READY TO SERVE",
              intervallo: ["da ",<span style={fW}>+3</span>," a ",<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "READY TO SERVE",
              intervallo: ["da ",<span style={fW}>+37,4</span>," a ",<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "LOW-TEMPERATURE COOKING",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "LOW-TEMPERATURE COOKING",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "LOW-TEMPERATURE COOKING",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "LOW-TEMPERATURE COOKING",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "LOW-TEMPERATURE COOKING",
              intervallo: [<span style={fW}>+65</span>,"°C"],
            },
            {
              title: "LOW-TEMPERATURE COOKING",
              intervallo: [<span style={fW}>+149</span>,"°F"],
            }
          ],
          [
            {
              title: "MELTING CHOCOLATE",
              intervallo: [<span style={fW}>+45</span>,"°C"],
            },
            {
              title: "MELTING CHOCOLATE",
              intervallo: [<span style={fW}>+113</span>,"°F"],
            }
          ],
          [
            {
              title: "DIRECT PROOFING",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "DIRECT PROOFING",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "DIRECT PROOFING",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "DIRECT PROOFING",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "DIRECT PROOFING",
              intervallo: [<span style={fW}>+30</span>,"°C"],
            },
            {
              title: "DIRECT PROOFING",
              intervallo: [<span style={fW}>+86</span>,"°F"],
            }
          ],
          [
            {
              title: "PROGRAMMED PROOFING",
              intervallo: ["da ",<span style={fW}>-18</span>," a ",<span style={fW}>+10</span>,"°C"],
            },
            {
              title: "PROGRAMMED PROOFING",
              intervallo: ["da ",<span style={fW}>-0,4</span>," a ",<span style={fW}>+50</span>,"°F"],
            }
          ],
          [
            {
              title: "PROGRAMMED PROOFING",
              intervallo: ["da ",<span style={fW}>+3</span>," a ",<span style={fW}>+10</span>,"°C"],
            },
            {
              title: "PROGRAMMED PROOFING",
              intervallo: ["da ",<span style={fW}>+37,4</span>," a ",<span style={fW}>+50</span>,"°F"],
            }
          ],
          [
            {
              title: "RETARDED PROOFING",
              intervallo: "",
            },
            {
              title: "RETARDED PROOFING",
              intervallo: "",
            }
          ],
          [
            {
              title: "PASTEURIZATION",
              intervallo: [<span style={fW}>-18</span>,"°C"],
            },
            {
              title: "PASTEURIZATION",
              intervallo: [<span style={fW}>-0,4</span>,"°F"],
            }
          ],
          [
            {
              title: "PASTEURIZATION",
              intervallo: [<span style={fW}>+3</span>,"°C"],
            },
            {
              title: "PASTEURIZATION",
              intervallo: [<span style={fW}>+37,4</span>,"°F"],
            }
          ],
          [
            {
              title: "DRYING",
              intervallo: "",
            },
            {
              title: "DRYING",
              intervallo: "",
            }
          ],
        ]
    },
    tour: {
      primoAccesso: [
        {
          lang: "EN",
          title: "Guide on times and temperatures",
          txt: [
            "Welcome to",
            "Get the best from your MF Next with the guide on times and temperatures studied by our chefs!"
          ],
          btn: "Let's start!",

        },
        {
          title: "Guida tempi e temperature richiede il tuo consenso per utilizzare i tuoi dati personali per:",
          txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nec sollicitudin diam. Suspendisse eget orci sagittis, porta dui sed, vestibulum est. Cras mollis interdum nisl, vitae ullamcorper turpis aliquam et. Suspendisse vulputate, metus ut ultrices congue, augue augue sollicitudin arcu, consectetur hendrerit sapien ipsum ac orci. Nam viverra semper lectus ut pulvinar. Nulla a leo vel risus vestibulum mattis. Etiam ut enim massa. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam blandit accumsan nunc, vitae lobortis justo finibus eget. Pellentesque vestibulum molestie justo, et iaculis augue feugiat sed.",
          btn1: "Acconsento",
          btn2: "Gestisci le opzioni"
        }
      ],
      tour:{
        schermoBlu: [
          {
            txt: "This is a short tutorial that will guide you in navigating your web app.",
            btn: "Start the tutorial!",
            salta: "SKIP"
          },
          {
            txt: "The tutorial has finished. Have a great time!",
            btn: "Go to the home!",
          }
        ],
        tour: [
          {
            txt: "This is the navbar. The navbar is a fixed component that you will find in almost every page of the web app.",
            btn: "Continue",
            salta: "SKIP"
          },
          {
            txt: "This is the home button. Each time you press it, it will bring you back to the home page.",
            btn: "Continue",
            salta: "SKIP"
          },
          {
            txt: "This is the search button. Each time you press it it will take you to the recipe book page and you can look for your application.",
            btn: "Continue",
            salta: "SKIP"
          },
          {
            txt: "This is the profile button. Each time you press it it will take you to the profile page.",
            btn: "Continue",
            salta: "SKIP"
          },
        ]
      }
    }     
}
export default propsEng;